import * as React from 'react'
import { oc } from 'ts-optchain'
import * as R from 'remeda'
import { GridItemDDOContext } from '../contexts/GridItemDDOContext'
import { EntityType, TListsState } from '../store/reducers/lists/interfaces'
import { useDispatchDeliveryOrder } from '../hooks/useDispatchDeliveryOrder'
import { useDeliveryOrder } from '../hooks/useDeliveryOrder'
import { useContainerType } from '../hooks/useContainerType'
import { useContainer } from '../hooks/useContainer'
import { useSteamShipLine } from '../hooks/useSteamShipLine'
import { useCustomer } from '../hooks/useCustomer'
import { useBusinessPartner } from '../hooks/useBusinessPartner'
import { useEquipment } from '../hooks/useEquipment'
import { useLocation } from '../hooks/useLocation'
import { useDriver } from '../hooks/useDriver'
import {
  ActivitiesViewDTO,
  BuySideQuoteDTO,
  DateISOString,
  DateTimeRangeDTO,
  DeliveryOrderViewDTO,
  DispatchDeliveryOrderViewDTO,
  LocationViewDTO,
  TransportationActivityViewDTO
} from '../api/api'
import {
  isChassisNumberRequired as isChassisNumberRequiredFn,
  CorrectActivityData
} from '../services/DTO/dispatchDeliveryOrder/functions'
import { useExpandedItem } from '../hooks/useExpandedItem'
import { useActivities } from '../hooks/useActivities'
import { ActivityGroup, TransportationActivityGroup } from '../services/DTO/activity/interfaces'
import { isDDOStatusWorking } from '../services/functions/test/isDDOStatusWorking'
import { groupActivities } from '../services/functions/group/groupActivities'
import { isDDOStatusCancelled } from '../services/functions/test/isDDOStatusCancelled'
import { getRelatedActivityType } from '../services/functions/get/getRelatedActivityType'
import { isDatesEqual } from '../services/functions/test/isDatesEqual'
import { useBuySideQuotes } from '../hooks/useBuySideQuotes'
import { calculateDDOQuotes } from '../services/functions/calculateDDOQuotes'
import { getListsState } from '../store'
import { calculateDDOProps } from '../services/functions/calculateDDOProps'
import { getShuttleByStages } from '../services/functions/get/getShuttleByStages'
import { isBusinessActivity } from '../services/functions/test/isBusinessActivity'
import { isCompletedActivity } from '../services/functions/test/isCompletedActivity'
import { isUnsuccessfulActivityGroup } from '../services/functions/test/isUnsuccessfulActivity'
import { useSellSideQuote } from '../hooks/useSellSideQuote'
import { useStreetTurn } from '../hooks/useStreetTurn'
import { isFullObject } from '../services/functions/test/isFullObject'
import { isDdoDisabled } from '../services/functions/test/isDdoDisabled'
import { isDateRangesEqual } from '../services/functions/test/isDateRangesEqual'

type Props = {
  id: string
  modifiedLists?: Partial<TListsState>
  children?: any
}

export const GridItemDDOProvider = React.memo((props: Props) => {
  const { id, children } = props
  const expandedItemData = useExpandedItem()
  const {
    modifiedLists,
    isModified,
    initialParentItemState,
    modifyListItems,
    deleteModifiedListItems
  } = expandedItemData
  const isExpanded = Boolean(modifiedLists)
  const dispatchDeliveryOrder = useDispatchDeliveryOrder({ id, modifiedLists })
  const activityIds = oc(dispatchDeliveryOrder).activityIds([])
  const buySideQuoteIds = oc(dispatchDeliveryOrder).buySideQuoteIds([])
  const deliveryOrder = useDeliveryOrder({ id: oc(dispatchDeliveryOrder).deliveryOrderId(), modifiedLists })
  const containerType = useContainerType({ id: oc(dispatchDeliveryOrder).containerTypeId(), modifiedLists })
  const container = useContainer({ id: oc(dispatchDeliveryOrder).containerId(), modifiedLists })
  const steamShipLine = useSteamShipLine({ id: oc(deliveryOrder).steamShipLineId(), modifiedLists })
  const customer = useCustomer({ id: oc(deliveryOrder).customerId(), modifiedLists })
  const customerBusinessPartner = useBusinessPartner({ id: oc(customer).businessPartnerId(), modifiedLists })
  const equipment = useEquipment({ id: oc(dispatchDeliveryOrder).equipmentId(), modifiedLists })
  const pickupLocation = useLocation({ id: oc(dispatchDeliveryOrder).pickupStage.locationId(), modifiedLists })
  const deliveryLocation = useLocation({ id: oc(dispatchDeliveryOrder).deliveryStage.locationId(), modifiedLists })
  const returnLocation = useLocation({ id: oc(dispatchDeliveryOrder).returnStage.locationId(), modifiedLists })
  const sellSideQuote = useSellSideQuote({ id: oc(dispatchDeliveryOrder).sellSideQuoteId(), modifiedLists })
  const streetTurn = useStreetTurn({ id: oc(dispatchDeliveryOrder).streetTurnId(), modifiedLists })
  // @ts-ignore
  const deliveryActivity: TransportationActivityViewDTO = oc(dispatchDeliveryOrder).deliveryStage.activity()
  const _deliveryActivityLocation = useLocation({ id: oc(deliveryActivity).destinationId() })
  const deliveryActivityLocation = _deliveryActivityLocation || (oc(deliveryActivity).destination() as LocationViewDTO)
  const deliveryActivityDriver = useDriver({ id: oc(deliveryActivity).vendorId() })
  const pickupDriver = useDriver({ id: oc(dispatchDeliveryOrder).pickupStage.vendorId(), modifiedLists })
  const deliveryDriver = useDriver({ id: oc(dispatchDeliveryOrder).deliveryStage.vendorId(), modifiedLists })
  const returnDriver = useDriver({ id: oc(dispatchDeliveryOrder).returnStage.vendorId(), modifiedLists })
  const deliveryOrderType = oc(deliveryOrder).type()
  const enableEditing = expandedItemData.enableEditing && (isModified || !isDdoDisabled(dispatchDeliveryOrder))
  const isQuotesModified = Boolean(
    [EntityType.buySideQuote, EntityType.sellSideQuote].some(t => {
      // @ts-ignore
      return Object.keys(oc(modifiedLists)[t]({})).length
    })
  )
  let declinedVendorIds: string[] = undefined
  let declinedVendorsNames = ''
  let activities: ActivitiesViewDTO = undefined
  let buySideQuotes: BuySideQuoteDTO[] = []
  let actualDDOStatus = oc(dispatchDeliveryOrder).status()
  let initialDdoStatus = oc(initialParentItemState).status() || actualDDOStatus
  let activityGroups: ActivityGroup[] = undefined
  let currentActivityGroup: TransportationActivityGroup = undefined
  let quoteAmounts = undefined

  const isImport = deliveryOrderType === DeliveryOrderViewDTO.TypeEnum.IMPORT
  const isExport = deliveryOrderType === DeliveryOrderViewDTO.TypeEnum.EXPORT
  const isRepo = deliveryOrderType === DeliveryOrderViewDTO.TypeEnum.REPOSITION
  const isReefer = oc(containerType)
    .name('')
    .toLowerCase()
    .includes('refriger')
  // const isBlumeServiceCreator = oc(dispatchDeliveryOrder)
  //   .createdBy('')
  //   .toLowerCase()
  //   .replace(/[^a-z]+/g, '')
  //   .includes('blumeservice')
  let isChassisNumberRequired = false

  if (isExpanded) {
    const storeDriverMapping = getListsState()[EntityType.driver]
    activities = useActivities({ ids: activityIds, modifiedLists })
    buySideQuotes = useBuySideQuotes({ ids: buySideQuoteIds, modifiedLists })
    activityGroups = groupActivities(activities)
    quoteAmounts = calculateDDOQuotes({ buySideQuotes, sellSideQuote })
    isChassisNumberRequired = isChassisNumberRequiredFn(activities)

    if (isFullObject(dispatchDeliveryOrder)) {
      if (isDDOStatusWorking(dispatchDeliveryOrder.status)) {
        const calculatedDDOProps = calculateDDOProps({
          activityGroups,
          dispatchDeliveryOrderStatus: oc(dispatchDeliveryOrder).status(),
          deliveryOrderType
        })
        actualDDOStatus = calculatedDDOProps.ddoStatus
        initialDdoStatus = initialDdoStatus || actualDDOStatus
        currentActivityGroup = calculatedDDOProps.currentActivityGroup
        declinedVendorIds = calculatedDDOProps.declinedVendorIds
        declinedVendorsNames = declinedVendorIds
          .map(_id => oc(storeDriverMapping)[_id].name())
          .filter(Boolean)
          .join(', ')
      }
    }
  }

  const cancelQuotesModifies = () => {
    deleteModifiedListItems({
      [EntityType.buySideQuote]: oc(dispatchDeliveryOrder).buySideQuoteIds([]),
      [EntityType.sellSideQuote]: [oc(dispatchDeliveryOrder).sellSideQuoteId()].filter(Boolean)
    })
  }

  const modifyDispatchDeliveryOrder = (_dispatchDeliveryOrder: DispatchDeliveryOrderViewDTO) => {
    modifyListItems({ [EntityType.dispatchDeliveryOrder]: [_dispatchDeliveryOrder] })
  }
  const modifyDispatchDeliveryOrderField = (key: keyof DispatchDeliveryOrderViewDTO) => (value: any) => {
    const modifiedDDO = { ...dispatchDeliveryOrder, [key]: value }

    if (key === 'loadType' && value === DispatchDeliveryOrderViewDTO.LoadTypeEnum.DROPANDPICK) {
      modifiedDDO.deliveryStage = { ...oc(modifiedDDO).deliveryStage({}), spentTimeSpan: undefined }
    } else if (key === 'hazmatIndicator') {
      modifiedDDO.deliveryOrder = { ...modifiedDDO.deliveryOrder, hazmatCutoffDate: null }
    } else if (key === 'autoIndicator') {
      modifiedDDO.deliveryOrder = { ...modifiedDDO.deliveryOrder, autoCutoffDate: null }
    }

    modifyDispatchDeliveryOrder(modifiedDDO)
  }
  const modifyDeliveryOrder = (_deliveryOrder: DeliveryOrderViewDTO) => {
    modifyListItems({ [EntityType.deliveryOrder]: [_deliveryOrder] })
  }
  const modifyDeliveryOrderField = (key: keyof DeliveryOrderViewDTO) => (value: any) => {
    modifyDeliveryOrder({ ...deliveryOrder, [key]: value })
  }
  const cancelDispatchDeliveryOrder = () => {
    const clearActivity = (activity: TransportationActivityViewDTO): TransportationActivityViewDTO => {
      return R.omit({ ...activity, status: TransportationActivityViewDTO.StatusEnum.NEW }, [
        'vendorId',
        'startActualDate',
        'completionActualDate'
      ])
    }

    const transportationGroups: TransportationActivityGroup[] = (activityGroups.filter(
      group => 'gotoActivity' in group && 'businessActivity' in group
    ) as TransportationActivityGroup[])
      .filter(
        group =>
          !isUnsuccessfulActivityGroup(group) &&
          !isCompletedActivity(group.gotoActivity) &&
          !isCompletedActivity(group.businessActivity)
      )
      .map(group => ({
        gotoActivity: clearActivity(group.gotoActivity),
        businessActivity: clearActivity(group.businessActivity)
      }))

    const modifiedTransportationActivities = transportationGroups
      .reduce((acc, curr) => {
        acc.push(curr.gotoActivity, curr.businessActivity)
        return acc
      }, [])
      .filter(Boolean)

    if (modifiedTransportationActivities.length) {
      modifyActivities({ transportationActivities: modifiedTransportationActivities })
    }

    modifyDispatchDeliveryOrderField('status')(DispatchDeliveryOrderViewDTO.StatusEnum.CANCELLED)
  }
  const modifyActivities = (_activities: ActivitiesViewDTO) => {
    const _transportationActivities = oc(_activities).transportationActivities([])
    const _documentationActivities = oc(_activities).documentationActivities([])
    // @ts-ignore
    const activityList = _transportationActivities.concat(_documentationActivities)

    if (activityList.length) {
      modifyListItems({ [EntityType.activity]: [...activityList] })
    }
  }
  const modifyActivityData = (activitiesData: CorrectActivityData) => {
    let transportationActivities = activitiesData.transportationActivities
    const documentationActivities = activitiesData.documentationActivities
    const stagesShuttle = getShuttleByStages({
      dispatchDeliveryOrder,
      deliveryOrderType,
      activityGroups: activitiesData.activityGroups
    })
    const needClearChassis =
      actualDDOStatus !== DispatchDeliveryOrderViewDTO.StatusEnum.COMPLETED &&
      activities.transportationActivities.filter(a => isBusinessActivity(a) && isCompletedActivity(a)).length <
        transportationActivities.filter(a => isBusinessActivity(a) && isCompletedActivity(a)).length &&
      isChassisNumberRequired &&
      !isChassisNumberRequiredFn({ transportationActivities })

    let pickupRelatedActivity = getRelatedActivityType({ transportationActivities, phaseType: 'pickup' })
    let appointmentRelatedActivity = getRelatedActivityType({ transportationActivities, phaseType: 'delivery' })
    let pickRelatedActivity = getRelatedActivityType({ transportationActivities, phaseType: 'pick' })
    let returnRelatedActivity = getRelatedActivityType({ transportationActivities, phaseType: 'return' })

    transportationActivities = transportationActivities.map(a => {
      const { startPlannedDateTimeRange, completionActualDate } = a
      let needConfirm = false

      if (!startPlannedDateTimeRange) {
        return a
      }

      if (completionActualDate) {
        needConfirm = true
      } else {
        const prevActivityState = oc(activities)
          .transportationActivities([])
          .find(_ => _.id === a.id)
        const isDateChanged = !isDateRangesEqual({
          dateRange1: oc(prevActivityState).startPlannedDateTimeRange(),
          dateRange2: startPlannedDateTimeRange,
          skipConfirmationCheck: true
        })

        if (isDateChanged) {
          needConfirm = true
        }
      }

      if (!needConfirm) {
        return a
      }

      const updatedActivity = {
        ...a,
        // @ts-ignore
        startPlannedDateTimeRange: { ...startPlannedDateTimeRange, confirmed: true }
      }

      if (oc(pickupRelatedActivity).id() === a.id) {
        pickupRelatedActivity = updatedActivity
      } else if (oc(appointmentRelatedActivity).id() === a.id) {
        appointmentRelatedActivity = updatedActivity
      } else if (oc(pickRelatedActivity).id() === a.id) {
        pickRelatedActivity = updatedActivity
      } else if (oc(returnRelatedActivity).id() === a.id) {
        returnRelatedActivity = updatedActivity
      }

      return updatedActivity
    })

    let pickupLocationId = oc(dispatchDeliveryOrder).pickupStage.locationId()
    let deliveryLocationId = oc(dispatchDeliveryOrder).deliveryStage.locationId()
    let returnLocationId = oc(dispatchDeliveryOrder).returnStage.locationId()

    transportationActivities.forEach(activity => {
      const { template, stage, destinationId } = activity

      if (template) {
        if (stage === TransportationActivityViewDTO.StageEnum.PICKUP) {
          pickupLocationId = destinationId
        } else if (stage === TransportationActivityViewDTO.StageEnum.DELIVERY) {
          deliveryLocationId = destinationId
        } else if (stage === TransportationActivityViewDTO.StageEnum.RETURN) {
          returnLocationId = destinationId
        }
      }
    })

    const _modifiedDDO = {
      ...dispatchDeliveryOrder,
      // @ts-ignore
      activityIds: (transportationActivities || []).concat(documentationActivities || []).map(a => a.id),
      status: isDDOStatusCancelled(dispatchDeliveryOrder.status) ? dispatchDeliveryOrder.status : actualDDOStatus,
      pickupStage: {
        ...oc(dispatchDeliveryOrder).pickupStage({}),
        shuttle: stagesShuttle[TransportationActivityViewDTO.StageEnum.PICKUP],
        locationId: pickupLocationId,
        plannedAppointmentDateTimeRange: pickupRelatedActivity
          ? pickupRelatedActivity.startPlannedDateTimeRange
          : dispatchDeliveryOrder.pickupStage.plannedAppointmentDateTimeRange,
        actualAppointmentDate: pickupRelatedActivity
          ? pickupRelatedActivity.completionActualDate
          : dispatchDeliveryOrder.pickupStage.actualAppointmentDate
      },
      deliveryStage: isRepo
        ? {}
        : {
            ...oc(dispatchDeliveryOrder).deliveryStage({}),
            locationId: deliveryLocationId,
            plannedAppointmentDateTimeRange: appointmentRelatedActivity
              ? appointmentRelatedActivity.startPlannedDateTimeRange
              : oc(dispatchDeliveryOrder).deliveryStage.plannedAppointmentDateTimeRange(),
            actualAppointmentDate: appointmentRelatedActivity
              ? appointmentRelatedActivity.completionActualDate
              : oc(dispatchDeliveryOrder).deliveryStage.actualAppointmentDate(),
            plannedPickDateTimeRange: pickRelatedActivity
              ? pickRelatedActivity.startPlannedDateTimeRange
              : oc(dispatchDeliveryOrder).deliveryStage.plannedPickDateTimeRange(),
            actualPickDate: pickRelatedActivity
              ? pickRelatedActivity.completionActualDate
              : oc(dispatchDeliveryOrder).deliveryStage.actualPickDate()
          },
      returnStage: {
        ...oc(dispatchDeliveryOrder).returnStage({}),
        shuttle: stagesShuttle[TransportationActivityViewDTO.StageEnum.RETURN],
        locationId: returnLocationId,
        plannedAppointmentDateTimeRange: returnRelatedActivity
          ? returnRelatedActivity.startPlannedDateTimeRange
          : dispatchDeliveryOrder.returnStage.plannedAppointmentDateTimeRange,
        actualAppointmentDate: returnRelatedActivity
          ? returnRelatedActivity.completionActualDate
          : dispatchDeliveryOrder.returnStage.actualAppointmentDate
      }
    }

    if (needClearChassis) {
      delete _modifiedDDO.equipmentId
    }

    modifyActivities({ documentationActivities, transportationActivities })
    modifyDispatchDeliveryOrder(_modifiedDDO)
  }
  const changeStageLocation = (_props: { stage: TransportationActivityViewDTO.StageEnum; locationId: string }) => {
    const { stage, locationId } = _props
    const fieldMapping = {
      [TransportationActivityViewDTO.StageEnum.PICKUP]: 'pickupStage',
      [TransportationActivityViewDTO.StageEnum.DELIVERY]: 'deliveryStage',
      [TransportationActivityViewDTO.StageEnum.RETURN]: 'returnStage'
    }

    // @ts-ignore
    modifyDispatchDeliveryOrderField(fieldMapping[stage])({
      // @ts-ignore
      ...oc(dispatchDeliveryOrder)[fieldMapping[stage]]({}),
      locationId
    })

    modifyActivities({
      transportationActivities: oc(activities)
        .transportationActivities([])
        .filter(activity => activity.template && activity.stage === stage)
        .map(activity => ({ ...activity, destinationId: locationId }))
    })
  }
  const modifyPhaseDate = (_props: {
    phaseType: 'pickup' | 'delivery' | 'pick' | 'return'
    date: DateISOString | DateTimeRangeDTO
    checkForDateChange?: boolean
    callBackIfDatesChanged?: () => any
  }) => {
    const { phaseType, checkForDateChange, date, callBackIfDatesChanged } = _props
    let stageField = 'pickupStage'
    let dateField = 'plannedAppointmentDateTimeRange'

    if (phaseType === 'pickup') {
      stageField = 'pickupStage'
      dateField = 'plannedAppointmentDateTimeRange'
    } else if (phaseType === 'delivery') {
      stageField = 'deliveryStage'
      dateField = 'plannedAppointmentDateTimeRange'
    } else if (phaseType === 'return') {
      stageField = 'returnStage'
      dateField = 'plannedAppointmentDateTimeRange'
    } else if (phaseType === 'pick') {
      stageField = 'deliveryStage'
      dateField = 'plannedPickDateTimeRange'
    }

    const relatedActivityType = getRelatedActivityType({
      transportationActivities: oc(activities).transportationActivities([]),
      phaseType
    })

    const update = () => {
      // @ts-ignore
      modifyDispatchDeliveryOrderField(stageField)({ ...oc(dispatchDeliveryOrder)[stageField]({}), [dateField]: date })

      if (relatedActivityType) {
        const updateActivity = oc(activities)
          .transportationActivities([])
          .find(a => a.id === relatedActivityType.id)

        if (updateActivity) {
          // @ts-ignore
          modifyActivities({ transportationActivities: [{ ...updateActivity, startPlannedDateTimeRange: date }] })
        }
      }
    }

    if (checkForDateChange) {
      if (!date || typeof date === 'string') {
        return update()
      }

      if (
        // @ts-ignore
        !isDatesEqual(oc(dispatchDeliveryOrder)[stageField][dateField].from(), date.from) ||
        // @ts-ignore
        !isDatesEqual(oc(dispatchDeliveryOrder)[stageField][dateField].to(), date.to) ||
        // @ts-ignore
        oc(dispatchDeliveryOrder)[stageField][dateField].confirmed() !== date.confirmed
      ) {
        if (callBackIfDatesChanged) {
          callBackIfDatesChanged()
        }

        update()
      }

      return
    }

    update()
  }

  return (
    <GridItemDDOContext.Provider
      value={{
        isExpanded,
        enableEditing,
        deliveryOrderType,
        dispatchDeliveryOrder,
        deliveryOrder,
        containerType,
        container,
        steamShipLine,
        customer,
        customerBusinessPartner,
        streetTurn,
        equipment,
        pickupLocation,
        deliveryLocation,
        returnLocation,
        sellSideQuote,
        buySideQuotes,
        pickupDriver,
        deliveryDriver,
        returnDriver,
        deliveryActivity,
        deliveryActivityLocation,
        deliveryActivityDriver,
        declinedVendorIds,
        declinedVendorsNames,
        activities,
        activityGroups,
        actualDDOStatus,
        initialDdoStatus,
        currentActivityGroup,
        isImport,
        isExport,
        isRepo,
        isReefer,
        isChassisNumberRequired,
        cancelDispatchDeliveryOrder,
        modifyDispatchDeliveryOrder,
        modifyDispatchDeliveryOrderField,
        modifyDeliveryOrder,
        modifyDeliveryOrderField,
        modifyActivityData,
        modifyActivities,
        modifyPhaseDate,
        changeStageLocation,
        quoteAmounts,
        isQuotesModified,
        cancelQuotesModifies
      }}
      children={children}
    />
  )
})
