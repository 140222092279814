import { oc } from 'ts-optchain'
import { DriverViewDTO } from '../../../api/api'

export const testDriverForReports = (driver: DriverViewDTO): string[] => {
  const errorMessages: string[] = []

  if (!driver.firstName) {
    errorMessages.push('First Name')
  }
  if (!driver.lastName) {
    errorMessages.push('Last Name')
  }
  if (!driver.birthday) {
    errorMessages.push('Date of Birth')
  }
  if (!driver.licenseNumber) {
    errorMessages.push('License Number')
  }
  if (!oc(driver).licenseAddress.stateId()) {
    errorMessages.push('License Address State')
  }
  if (!oc(driver).licenseIssueStateId()) {
    errorMessages.push('Issue State')
  }
  if (!oc(driver).applicationDocumentFile()) {
    errorMessages.push('Appllication Form')
  }

  return errorMessages
}
