import { DriverViewDTO } from '../../../api/api'
import { testDriverStatus } from '../test/testDriverStatus'

type Props = {
  trim: string
  drivers: DriverViewDTO[]
  isCandidates?: boolean
}

export const filterDrivers = (props: Props): DriverViewDTO[] => {
  const { trim, drivers, isCandidates } = props
  let list = drivers

  if (!(list && list.length)) {
    return []
  }

  if (typeof isCandidates === 'boolean') {
    list = list.filter(driver => testDriverStatus(driver.status).isCandidate === isCandidates)
  }

  if (!trim) {
    return list
  }

  return list.filter(
    driver =>
      driver.status !== DriverViewDTO.StatusEnum.TERMINATED &&
      driver.name &&
      driver.name.toUpperCase().includes(trim.toUpperCase())
  )
}
