import { oc } from 'ts-optchain'
import * as R from 'remeda'
import { DriverViewDTO, OngoingActivityDTO, OngoingActivityGroupDTO } from '../../../../api/api'
import { SchedulerSortBy } from '../interfaces'
import { groupTitleWithoutDataMapping } from './generateGroupTitleWithoutData'
import { getListsState } from '../../../../store'
import { EntityType } from '../../../../store/reducers/lists/interfaces'
import { isBusinessActivity } from '../../../../services/functions/test/isBusinessActivity'

export type TSortOngoingActivityGroups = {
  sortedListWithTargetData: Record<string, OngoingActivityGroupDTO[]>
  sortedListWithoutTargetData: OngoingActivityGroupDTO[]
}

export const sortOngoingActivityGroups = (sortBy: SchedulerSortBy, driverMapping: Record<string, DriverViewDTO>) => (
  ongoingActivityGroups: OngoingActivityGroupDTO[]
): TSortOngoingActivityGroups => {
  const defaultGroupTitleWithoutData = groupTitleWithoutDataMapping[sortBy] || 'Other'
  switch (sortBy) {
    case SchedulerSortBy.deliveryLocationCity: {
      const storeLists = getListsState()
      const locationMapping = storeLists[EntityType.location]
      const stateMapping = storeLists[EntityType.state]
      const result: Record<string, OngoingActivityGroupDTO[]> = {}
      let groupItems: Record<string, Record<string, OngoingActivityGroupDTO[]>> = {}
      groupItems = ongoingActivityGroups.reduce((acc, curr) => {
        if (!isActivityGroupCompleted(curr.deliveryActivity)) {
          const locationId = oc(curr).deliveryActivity.destinationId()
          const location = locationMapping[locationId]
          const city = oc(location).address.city(defaultGroupTitleWithoutData)
          const state = oc(stateMapping)[oc(location).address.stateId()].code('')

          if (!acc[city]) {
            acc[city] = {}
          }

          if (!acc[city][state]) {
            acc[city][state] = []
          }

          acc[city][state].push(curr)
        }

        return acc
      }, groupItems)

      Object.keys(groupItems).forEach(city => {
        Object.keys(groupItems[city])
          .sort((stateA, stateB) => stateA.localeCompare(stateB))
          .forEach(state => {
            const key = [city, state].filter(Boolean).join(', ')
            result[key] = groupItems[city][state].sort(
              (a, b) => Date.parse(a.activityGroupDate) - Date.parse(b.activityGroupDate)
            )
          })
      })

      return {
        sortedListWithTargetData: R.omit(result, [defaultGroupTitleWithoutData]),
        sortedListWithoutTargetData: result[defaultGroupTitleWithoutData] || []
      }
    }
    case SchedulerSortBy.deliveryStageDriver: {
      let groupItems: Record<string, OngoingActivityGroupDTO[]> = {}
      groupItems = ongoingActivityGroups.reduce((acc, curr) => {
        if (!isActivityGroupCompleted(curr.deliveryActivity)) {
          const driverName = oc(driverMapping[oc(curr).deliveryActivity.driverId()]).name(defaultGroupTitleWithoutData)

          if (!acc[driverName]) {
            acc[driverName] = []
          }

          acc[driverName].push(curr)
        }

        return acc
      }, groupItems)

      Object.keys(groupItems).forEach(driver => {
        groupItems[driver].sort((a, b) => Date.parse(a.activityGroupDate) - Date.parse(b.activityGroupDate))
      })

      return {
        sortedListWithTargetData: R.omit(groupItems, [defaultGroupTitleWithoutData]),
        sortedListWithoutTargetData: groupItems[defaultGroupTitleWithoutData] || []
      }
    }
    case SchedulerSortBy.returnStageDriver: {
      let groupItems: Record<string, OngoingActivityGroupDTO[]> = {}
      groupItems = ongoingActivityGroups.reduce((acc, curr) => {
        if (!isActivityGroupCompleted(curr.returnActivity)) {
          const driverName = oc(driverMapping[oc(curr).returnActivity.driverId()]).name(defaultGroupTitleWithoutData)

          if (!acc[driverName]) {
            acc[driverName] = []
          }

          acc[driverName].push(curr)
        }

        return acc
      }, groupItems)

      Object.keys(groupItems).forEach(driver => {
        groupItems[driver].sort((a, b) => Date.parse(a.activityGroupDate) - Date.parse(b.activityGroupDate))
      })

      return {
        sortedListWithTargetData: R.omit(groupItems, [defaultGroupTitleWithoutData]),
        sortedListWithoutTargetData: groupItems[defaultGroupTitleWithoutData] || []
      }
    }
    case SchedulerSortBy.driverAllStages: {
      let groupItems: Record<string, OngoingActivityGroupDTO[]> = {}
      groupItems = ongoingActivityGroups.reduce((acc, ongoingActivity) => {
        const driverName = ongoingActivity.activityGroupDriver

        if (!acc[driverName]) {
          acc[driverName] = []
        }

        acc[driverName].push(ongoingActivity)

        return acc
      }, groupItems)

      Object.keys(groupItems).forEach(driver => {
        groupItems[driver].sort((a, b) => Date.parse(a.activityGroupDate) - Date.parse(b.activityGroupDate))
      })

      return {
        sortedListWithTargetData: R.omit(groupItems, [defaultGroupTitleWithoutData]),
        sortedListWithoutTargetData: groupItems[defaultGroupTitleWithoutData] || []
      }
    }
    default:
      return {
        sortedListWithTargetData: {},
        sortedListWithoutTargetData: []
      }
  }
}

const isActivityGroupCompleted = (activity: OngoingActivityDTO): boolean => {
  return isBusinessActivity(activity) && oc(activity).status() === OngoingActivityDTO.StatusEnum.COMPLETED
}
