import { oc } from 'ts-optchain'
import {
  DispatchDeliveryOrderStreetTurnDTO,
  DispatchDeliveryOrderViewDTO,
  TransportationActivityDTO
} from '../../../api/api'
import { ColumnDTO } from '../../../store/reducers/tabs/interfaces'
import { getContainerTypeMapping } from '../../../store'

type Props = {
  dispatchDeliveryOrder: DispatchDeliveryOrderViewDTO
  filter: ColumnDTO.Filter
}

export const isWebSocketGridDdoValid = (props: Props): boolean => {
  const { dispatchDeliveryOrder, filter } = props

  if (!(dispatchDeliveryOrder && filter && filter.column && filter.value)) {
    return false
  }

  const { column, value } = filter

  if (column === 'streetTurn.status' && Array.isArray(value)) {
    if (value.includes(String(DispatchDeliveryOrderStreetTurnDTO.StatusEnum.AVAILABLE))) {
      if (oc(dispatchDeliveryOrder).streetTurnCount(0) > 0 && !dispatchDeliveryOrder.streetTurnId) {
        return true
      }
    }

    if (value.includes(String(DispatchDeliveryOrderStreetTurnDTO.StatusEnum.REJECTED))) {
      return oc(dispatchDeliveryOrder).hasRejectedStreetTurn(false)
    }
  }

  if (column === 'containerType.code' && typeof value === 'string' && dispatchDeliveryOrder.containerTypeId) {
    const container = getContainerTypeMapping()[dispatchDeliveryOrder.containerTypeId]

    if (container && container.code) {
      return value.toUpperCase().includes(container.code.toUpperCase())
    }
  }

  return false
}
