import * as React from 'react'
import { CustomHeader as CustomDDOHeader } from '../../../common/dispatchDeliveryOrder/views/Details/CustomHeader'
import { EntityType } from '../../../../store/reducers/lists/interfaces'
import { CustomDriverHeader } from '../../../common/drivers/views/Details/CustomHeader'

type Props = {
  entityType: EntityType
}

export const CustomUnitHeader = ({ entityType }: Props) => {
  switch (entityType) {
    case EntityType.driver:
      return <CustomDriverHeader />
    case EntityType.dispatchDeliveryOrder:
      return <CustomDDOHeader />
    default:
      return null
  }
}
