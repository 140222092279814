import {
  DeliveryOrderViewDTO,
  DispatchDeliveryOrderViewDTO,
  LocationViewDTO,
  TransportationActivityViewDTO
} from '../../../api/api'
import { oc } from 'ts-optchain'
// tslint:disable:max-line-length
import { completionActualDayNeedStatuses } from '../../../components/common/dispatchDeliveryOrder/views/Details/Activities/Table/Body/Row/Column/CompletionActualDateActivityColumn'
// tslint:disable:max-line-length
import { startActualDayNeedStatuses } from '../../../components/common/dispatchDeliveryOrder/views/Details/Activities/Table/Body/Row/Column/StartActualDateActivityColumn'
import { isGotoActivity } from '../../functions/test/isGotoActivity'
import { isDDOStatusCancelled } from '../../functions/test/isDDOStatusCancelled'
import { dateService } from '../../timeService'
import { debuggingMode } from '../../debug'

export const allowActivitiesModifying = (dispatchDeliveryOrder: DispatchDeliveryOrderViewDTO) => {
  return ![
    DispatchDeliveryOrderViewDTO.StatusEnum.NEW,
    DispatchDeliveryOrderViewDTO.StatusEnum.NEWACCEPTED,
    DispatchDeliveryOrderViewDTO.StatusEnum.NEWREJECTED,
    DispatchDeliveryOrderViewDTO.StatusEnum.HOLDLOADED,
    DispatchDeliveryOrderViewDTO.StatusEnum.HOLDARRIVED,
    DispatchDeliveryOrderViewDTO.StatusEnum.HOLDUNLOADED,
    DispatchDeliveryOrderViewDTO.StatusEnum.RELEASEDLOADED,
    DispatchDeliveryOrderViewDTO.StatusEnum.RELEASEDARRIVED,
    DispatchDeliveryOrderViewDTO.StatusEnum.RELEASEDARRIVED,
    DispatchDeliveryOrderViewDTO.StatusEnum.RELEASEDUNLOADED,
    DispatchDeliveryOrderViewDTO.StatusEnum.ONFILE,
    DispatchDeliveryOrderViewDTO.StatusEnum.NOTONFILE,
    DispatchDeliveryOrderViewDTO.StatusEnum.CANCELLED
  ].includes(dispatchDeliveryOrder.status)
}

export const isDDOActive = (dispatchDeliveryOrder: DispatchDeliveryOrderViewDTO) =>
  Boolean(
    dispatchDeliveryOrder.status !== DispatchDeliveryOrderViewDTO.StatusEnum.COMPLETED &&
      !isDDOStatusCancelled(dispatchDeliveryOrder.status)
  )

export const isDDOHasLocationsWithActiveTerminalIntegration = (props: {
  pickupLocation: LocationViewDTO
  returnLocation: LocationViewDTO
  deliveryOrderType: DeliveryOrderViewDTO.TypeEnum
}): boolean => {
  const { pickupLocation, returnLocation, deliveryOrderType } = props

  switch (deliveryOrderType) {
    case DeliveryOrderViewDTO.TypeEnum.IMPORT:
      return Boolean(oc(pickupLocation).terminalIntegration.active())
    case DeliveryOrderViewDTO.TypeEnum.EXPORT:
      return Boolean(oc(returnLocation).terminalIntegration.active())
    default:
      return false
  }
}

export const checkActivity = (activity: TransportationActivityViewDTO) => {
  const vendorNeedStatuses = [
    TransportationActivityViewDTO.StatusEnum.DRIVERASSIGNED,
    TransportationActivityViewDTO.StatusEnum.DRIVERCONFIRMED,
    TransportationActivityViewDTO.StatusEnum.INPROCESS,
    TransportationActivityViewDTO.StatusEnum.COMPLETED
  ]

  const checkDateTime = (date: string): boolean => {
    return true
    // if (debuggingMode.simplifyActivityDateValidation) {
    //   return true
    // }

    // return dateService.isTimeFilled(date)
  }

  return [
    activity.dispatchDeliveryOrderId,
    activity.number >= 0,
    activity.stage,
    activity.status,
    activity.type,
    vendorNeedStatuses.includes(activity.status)
      ? activity.vendorId && (activity.originId || activity.destinationId)
      : true,
    startActualDayNeedStatuses.includes(activity.status)
      ? Boolean(activity.startActualDate && checkDateTime(activity.startActualDate))
      : true,
    completionActualDayNeedStatuses.includes(activity.status)
      ? Boolean(activity.completionActualDate && checkDateTime(activity.completionActualDate))
      : true,
    activity.notes ? activity.notes.length <= 200 : true
  ].every(Boolean)
}

export const filterUsefulTransportationActivities = (
  transportationActivities: TransportationActivityViewDTO[]
): TransportationActivityViewDTO[] =>
  transportationActivities
    .sort((a, b) => a.number - b.number)
    .filter(
      (activity, index) =>
        activity.status !== TransportationActivityViewDTO.StatusEnum.UNSUCCESSFUL &&
        !(
          isGotoActivity(activity) &&
          transportationActivities[index + 1].status === TransportationActivityViewDTO.StatusEnum.UNSUCCESSFUL
        )
    )
