import { DriverViewDTO } from '../../../api/api'
import { groupedDriverStatuses } from '../../../constants/driverStatuses'

type Props = {
  driverStatus: DriverViewDTO.StatusEnum
}

export const isDriverResetAvailable = (props: Props): boolean => {
  const { driverStatus } = props

  return groupedDriverStatuses.Candidate.includes(driverStatus)
}
