import * as React from 'react'
import { oc } from 'ts-optchain'
import { WidgetTable, RowTitleColumn } from '../../../../popups/Table'
import { TerminalIntegrationTable } from './styles'
import { WidgetContext } from '../../index'
import { DispatchDeliveryOrderViewDTO, TerminalMismatchDTO } from '../../../../../api/api'
import { GridItemSpinner } from '../../../Spinner/Spinner'
import { requestTerminalMismatches } from '../../../../common/dispatchDeliveryOrder/epics'

type Props = {
  terminalCode: string
  dispatchDeliveryOrder: DispatchDeliveryOrderViewDTO
}

export const Popover = (props: Props) => {
  const { terminalCode, dispatchDeliveryOrder } = props
  const { closePopover } = React.useContext(WidgetContext)
  const [terminalMismatches, setTerminalMismatches] = React.useState<TerminalMismatchDTO[]>([])
  const [fetching, setFetchig] = React.useState(true)
  const hasDescription = terminalMismatches.some(_ => _.systemValueDescription || _.terminalValueDescription)

  React.useEffect(() => {
    requestTerminalMismatches({ ddoId: oc(dispatchDeliveryOrder).id() })
      .then(setTerminalMismatches)
      .finally(() => setFetchig(false))
  }, [])

  React.useEffect(() => {
    if (!fetching && !terminalMismatches.length) {
      closePopover()
    }
  }, [fetching, terminalMismatches.length])

  return (
    <TerminalIntegrationTable style={{ position: 'relative' }}>
      {fetching && <GridItemSpinner />}
      <WidgetTable
        header={
          hasDescription ? ['Mismatch', 'System', terminalCode, 'Description'] : ['Mismatch', 'System', terminalCode]
        }
        body={terminalMismatches.reduce((acc, mismath) => {
          const constructedArr = [
            <RowTitleColumn key={mismath.fieldName}>{mismath.fieldName}</RowTitleColumn>,
            mismath.systemValue,
            mismath.terminalValue
          ]
          if (hasDescription) {
            constructedArr.push(
              [mismath.systemValueDescription, mismath.terminalValueDescription]
                .filter(Boolean)
                .join(`\n`)
                .replace(/; /gi, ';')
                .split(';')
                .join(';\n')
            )
          }
          acc.push(constructedArr)
          return acc
        }, [])}
        columnWidthEvenly={!hasDescription}
      />
    </TerminalIntegrationTable>
  )
}
