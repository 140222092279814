import { deliveryOrderLists } from '../../select/deliveryOrderLists'
import { dispatchDeliveryOrderLists } from '../../select/dispatchDeliveryOrderLists'
// tslint:disable:max-line-length
import { DispatchDeliveryOrderColumn } from '../../../components/common/grid/columnSettings/dispatchDeliveryOrderColumnSettings'
import { regExpOnlyNumbersAndLetters } from '../../functions/regExp'
import { activityLists } from '../../select/activityLists'
import { ColumnDTO } from '../../../store/reducers/tabs/interfaces'

export const dispatchDeliveryOrderFilterSettings = {
  [DispatchDeliveryOrderColumn.Notifications]: {
    name: 'number',
    type: ColumnDTO.Type.number,
    disabled: true
  },
  [DispatchDeliveryOrderColumn.DDONumber]: {
    name: 'number',
    type: ColumnDTO.Type.number
  },
  [DispatchDeliveryOrderColumn.ETA]: {
    newDateTimePicker: true,
    name: 'deliveryOrder.vesselArrivalDate',
    type: ColumnDTO.Type.date
  },
  [DispatchDeliveryOrderColumn.DONumber]: {
    name: 'deliveryOrder.number',
    type: ColumnDTO.Type.number
  },
  [DispatchDeliveryOrderColumn.Status]: {
    name: 'status',
    type: ColumnDTO.Type.multiSelectList,
    items: dispatchDeliveryOrderLists.status
  },
  [DispatchDeliveryOrderColumn.Type]: {
    name: 'deliveryOrder.type',
    type: ColumnDTO.Type.list,
    items: deliveryOrderLists.type
  },
  [DispatchDeliveryOrderColumn.Equipment]: {
    name: 'containerType.code',
    type: ColumnDTO.Type.string
  },
  [DispatchDeliveryOrderColumn.Container]: {
    name: 'container.number',
    type: ColumnDTO.Type.string,
    regExp: regExpOnlyNumbersAndLetters.source
  },
  [DispatchDeliveryOrderColumn.StreetTurn]: {
    name: 'streetTurn.status',
    type: ColumnDTO.Type.multiSelectList,
    items: dispatchDeliveryOrderLists.streetTurn,
    disableSorting: true
  },
  [DispatchDeliveryOrderColumn.SSL]: {
    name: 'deliveryOrder.steamShipLine.name',
    type: ColumnDTO.Type.string
  },
  [DispatchDeliveryOrderColumn.LoadType]: {
    name: 'loadType',
    type: ColumnDTO.Type.list,
    items: dispatchDeliveryOrderLists.loadType
  },
  [DispatchDeliveryOrderColumn.Chassis]: {
    name: 'equipment.chassisNumber',
    type: ColumnDTO.Type.string
  },
  [DispatchDeliveryOrderColumn.BookingBL]: {
    name: 'deliveryOrder.docNumberAndRefNumber',
    path: 'deliveryOrder.docNumber',
    type: ColumnDTO.Type.string,
    combinationOfValuePaths: ['deliveryOrder.docNumber', 'deliveryOrder.cargo.referenceNumber'],
    regExp: regExpOnlyNumbersAndLetters.source
  },
  [DispatchDeliveryOrderColumn.Customer]: {
    name: 'deliveryOrder.customer.name',
    type: ColumnDTO.Type.string
  },
  [DispatchDeliveryOrderColumn.Pickup]: {
    name: 'pickupStage.location.longName',
    path: 'pickupStage.location.longName',
    type: ColumnDTO.Type.string
  },
  [DispatchDeliveryOrderColumn.PickupDriver]: {
    name: 'pickupStage.vendor.name',
    type: ColumnDTO.Type.string
  },
  [DispatchDeliveryOrderColumn.Delivery]: {
    name: 'deliveryStage.activity.destination.longName',
    sort: 'deliveryStage.activity.destination.cityStateZip',
    type: ColumnDTO.Type.string
  },
  [DispatchDeliveryOrderColumn.DeliveryDriver]: {
    name: 'deliveryStage.activity.vendor.name',
    type: ColumnDTO.Type.string
  },
  [DispatchDeliveryOrderColumn.DeliveryActivity]: {
    name: 'deliveryStage.activity.type',
    items: activityLists.businessType,
    type: ColumnDTO.Type.list
  },
  [DispatchDeliveryOrderColumn.ActivityPlanned]: {
    name: 'deliveryStage.activity.startPlannedDateTimeRange.dateFrom',
    path: 'deliveryStage.activity.startPlannedDateTimeRange.from',
    newDateTimePicker: true,
    type: ColumnDTO.Type.date
  },
  [DispatchDeliveryOrderColumn.ActivityCompletion]: {
    name: 'deliveryStage.activity.completionActualDate',
    type: ColumnDTO.Type.date
    // newDateTimePicker: true
  },
  [DispatchDeliveryOrderColumn.PickUpPlanned]: {
    name: 'pickupStage.plannedAppointmentDateTimeRange.dateFrom',
    path: 'pickupStage.plannedAppointmentDateTimeRange.from',
    type: ColumnDTO.Type.date,
    newDateTimePicker: true
  },
  [DispatchDeliveryOrderColumn.Appointment]: {
    name: 'deliveryStage.appointmentDate',
    type: ColumnDTO.Type.date,
    combinationOfValuePaths: ['deliveryStage.appointmentDate', 'deliveryStage.pickDate'],
    newDateTimePicker: true
  },
  [DispatchDeliveryOrderColumn.ReturnPlanned]: {
    name: 'returnStage.plannedAppointmentDateTimeRange.dateFrom',
    path: 'returnStage.plannedAppointmentDateTimeRange.from',
    type: ColumnDTO.Type.date,
    newDateTimePicker: true
  },
  [DispatchDeliveryOrderColumn.ApptDateStatus]: {
    name: 'deliveryStage.plannedAppointmentDateTimeRange.confirmed',
    type: ColumnDTO.Type.list,
    items: [
      {
        label: 'Confirmed',
        value: 'true'
      },
      {
        label: 'Not Confirmed',
        value: 'false'
      },
      {
        label: 'All',
        value: ''
      }
    ]
  },
  [DispatchDeliveryOrderColumn.Return]: {
    name: 'returnStage.location.longName',
    path: 'returnStage.location.longName',
    type: ColumnDTO.Type.string
  },
  [DispatchDeliveryOrderColumn.ReturnDriver]: {
    name: 'returnStage.vendor.name',
    type: ColumnDTO.Type.string
  },
  [DispatchDeliveryOrderColumn.DaysOut]: {
    name: 'daysOut',
    type: ColumnDTO.Type.number
  },
  [DispatchDeliveryOrderColumn.Availability]: {
    newDateTimePicker: true,
    name: 'deliveryOrder.equipmentFirstPickupDate',
    type: ColumnDTO.Type.date
  },
  [DispatchDeliveryOrderColumn.CutOffLFD]: {
    newDateTimePicker: true,
    name: 'deliveryOrder.lastFreeDateAndGeneralCutoffDate',
    path: 'deliveryOrder.lastFreeDateDemurrage',
    combinationOfValuePaths: ['deliveryOrder.lastFreeDateDemurrage', 'deliveryOrder.generalCutoffDate'],
    type: ColumnDTO.Type.date
  },
  // [DispatchDeliveryOrderColumn.Cutoff]: {
  //   name: 'deliveryOrder.generalCutoffDate',
  //   type: GridHeaderColumnType.date,
  //   disabled: true
  // },
  [DispatchDeliveryOrderColumn.PerDiemFreeBy]: {
    newDateTimePicker: true,
    name: 'deliveryOrder.lastFreeDatePerDiem',
    type: ColumnDTO.Type.date
  },
  [DispatchDeliveryOrderColumn.FirstReceiving]: {
    newDateTimePicker: true,
    name: 'deliveryOrder.firstReceivingDate',
    type: ColumnDTO.Type.date
  },
  [DispatchDeliveryOrderColumn.DOCreated]: {
    newDateTimePicker: true,
    name: 'deliveryOrder.date',
    type: ColumnDTO.Type.date
  }
}
