import * as React from 'react'
import styled from 'styled-components'
import { ActionButton } from '../../../../../UI/Buttons/ActionButton'
import theme from '../../../../../../styles/theme'
import { isDriverResetAvailable } from '../../../../../../services/functions/test/isDriverResetAvailable'
import { useGridItemDriver } from '../../../../../../hooks/useGridItemDriver'
import { oc } from 'ts-optchain'
import { showWarningModalWindow } from '../../../../../../store/reducers/modalWindow/functions'
import { requestDriverReset } from '../../../epics'
import { useExpandedItem } from '../../../../../../hooks/useExpandedItem'

type Props = {}

export const CustomDriverHeader = React.memo((props: Props) => {
  const { setFetching, isModified } = useExpandedItem()
  const { driver } = useGridItemDriver()

  if (isModified || !isDriverResetAvailable({ driverStatus: oc(driver).status() })) {
    return null
  }

  return (
    <Container>
      <ActionButton
        styles={{ backgroundColor: theme.colors.orange, borderColor: theme.colors.orange }}
        filled={true}
        onClick={() => {
          showWarningModalWindow({
            width: 310,
            title: `Are you sure you want\n to reset the driver?`,
            content: 'All Driver information will be erased except for the Driver Personal and contact Details',
            buttons: [
              { label: 'Cancel', outline: true },
              {
                label: 'Confirm',
                onClick: () => {
                  setFetching(true)
                  requestDriverReset(driver.id).finally(() => setFetching(false))
                }
              }
            ]
          })
        }}
      >
        Reset Driver
      </ActionButton>
    </Container>
  )
})

const Container = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
`
