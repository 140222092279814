import * as React from 'react'
import { oc } from 'ts-optchain'
import { OngoingActivityGroupDTO } from '../../../../../api/api'
import { useAppSelector } from '../../../../../hooks/useAppSelector'
import { getDriverPositionByActivityIds } from '../../../../../services/DTO/driverPosition/epics'
import { selectOngoingActivitisDateStageNow } from '../../../../../store/select/ongoingActivitySelect'
import { filterOngoingActivityGroups } from '../../functions/filterOngoingActivityGroups'
import { ISchedulerTabFilterMapping, SchedulerSortBy } from '../../interfaces'
import { CollapsibleGroup } from './CollapsibleGroup'

type OwnProps = {
  expanded: boolean
  sortBy: SchedulerSortBy
  todayTimeGroupActivities: { [time: string]: OngoingActivityGroupDTO[] }
  filter: ISchedulerTabFilterMapping
  onToggle: () => void
}

type StateProps = {}

type Props = OwnProps & StateProps

let allDriverPositionsHasBeenLoaded = false

export const NowActivityGroups = React.memo((props: Props) => {
  const { expanded, sortBy, filter, onToggle, todayTimeGroupActivities } = props
  const activityGroups = useAppSelector(selectOngoingActivitisDateStageNow)
  const activityGroupList = React.useMemo(() => {
    let _activityGroupList = Object.values(activityGroups)
    const todayOngoingDispatchDeliveryOrderIds: string[] = Object.values(todayTimeGroupActivities || {}).reduce(
      (acc, curr) => {
        const ongoingGroupActivities = Object.values(curr)
        ongoingGroupActivities.forEach(({ dispatchDeliveryOrderId }) => {
          acc.push(dispatchDeliveryOrderId)
        })

        return acc
      },
      [] as string[]
    )
    if (todayOngoingDispatchDeliveryOrderIds && todayOngoingDispatchDeliveryOrderIds.length) {
      _activityGroupList = _activityGroupList.filter(
        ({ dispatchDeliveryOrderId }) => !todayOngoingDispatchDeliveryOrderIds.includes(dispatchDeliveryOrderId)
      )
    }
    return filterOngoingActivityGroups(_activityGroupList, filter)
  }, [activityGroups, todayTimeGroupActivities, filter])

  React.useEffect(() => {
    let isExpiredData = false

    if (!allDriverPositionsHasBeenLoaded) {
      const activityIds = Object.values(activityGroups).reduce((acc, activityGroup) => {
        const ids = [
          activityGroup.pickupActivity,
          activityGroup.deliveryActivity,
          activityGroup.returnActivity,
          activityGroup.extraActivity
        ]
          .map(activity => oc(activity).activityId())
          .filter(Boolean)

        acc.push(...ids)
        return acc
      }, [])

      if (activityIds.length) {
        getDriverPositionByActivityIds(activityIds).then(() => {
          if (filter && !filter.dispatchDeliveryOrderId) {
            allDriverPositionsHasBeenLoaded = true
          }
        })
      }
    }

    return () => {
      isExpiredData = true
    }
  }, [activityGroups])

  return (
    <CollapsibleGroup
      title={'Now'}
      isNowSection={true}
      labelBackgroundColor={'rgb(97 114 137)'}
      expanded={expanded}
      sortBy={sortBy}
      activityGroups={activityGroupList}
      onToggle={onToggle}
    />
  )
})
