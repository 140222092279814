import * as React from 'react'
import cn from 'classnames'
import { Whitespace } from '../../../../../services/keyboardService/keys'
import { TimeField } from './styles'
import { dateService, twoDigit } from '../../../../../services/timeService'
// tslint:disable-next-line:no-var-requires
const ReactInputMask = require('react-input-mask')

type Props = {
  onChange: (time: { hours: number; minutes: number }) => void
  required?: boolean
  disabled?: boolean
  date?: string
}

export const TimeInput = React.memo((props: Props) => {
  const { date, onChange, disabled, required } = props
  const [time, setTime] = React.useState<string>('')
  const [valid, setValid] = React.useState<boolean>(true)
  const dateObject = dateService.convertFromServer(date)
  const hh = dateObject && dateObject.getHours()
  const mm = dateObject && dateObject.getMinutes()
  let dateTime = ''

  if (required ? dateService.isTimeFilled(date) : dateObject) {
    dateTime = `${twoDigit(hh)}:${twoDigit(mm)}`
  }

  React.useEffect(() => {
    setValid(true)
    setTime('')
  }, [date])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    const [hours, minutes] = value.replace(/-/g, '').split(':')

    if (!hours && !minutes) {
      return onChange({ hours: undefined, minutes: undefined })
    }

    const isTimeValid = testTime(hours, minutes)

    setValid(isTimeValid)

    if (!isTimeValid) {
      return
    }

    const _hh = Number(hours)
    const _mm = Number(minutes)

    if (_hh !== hh || _mm !== mm) {
      onChange({ hours: _hh, minutes: _mm })
    }

    setTime('')
  }

  return (
    <TimeField className={cn({ timeRequired: required })}>
      <ReactInputMask
        disabled={disabled}
        style={!valid ? { color: 'red' } : undefined}
        placeholder={'00:00'}
        alwaysShowMask={false}
        mask={'99:99'}
        maskChar={'-'}
        onKeyPress={(event: React.KeyboardEvent<HTMLInputElement>) => {
          if (event.key === Whitespace.Enter) {
            event.preventDefault()
            event.stopPropagation()
            // @ts-ignore
            event.target.blur()
          }
        }}
        value={time || dateTime || ''}
        onFocus={() => setValid(true)}
        onBlur={handleChange}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setTime(event.target.value)}
      />
    </TimeField>
  )
})

const testTime = (hh: string | number, mm: string | number): boolean => {
  try {
    const hours = Number(hh)
    const minutes = Number(mm)
    return hours < 24 && minutes < 60
  } catch (e) {
    return false
  }
}
