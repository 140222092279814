import * as React from 'react'
import { oc } from 'ts-optchain'
import styled from 'styled-components'
import { DocumentationActivityRow } from '../../../../../../../../../services/DTO/activity/controller'
import { activityStageColor, StyledActivityRow, ColorLine, DabbleColumn, ActivityColumn } from '../../styles'
import { ddoDirectory } from '../../../../../../../../../services/DTO/dispatchDeliveryOrder/directory'
import { DateTypes } from '../../../../../../../../UI/DatePicker/Interfaces'
import DateField from '../../../../../../../../UI/DatePicker/DateField'
import { openDDOinNewTab } from '../../../../../../../../../services/DTO/dispatchDeliveryOrder'
import { DispatchDeliveryOrderViewDTO } from '../../../../../../../../../api/api'
import { DateTimePicker } from '../../../../../../../../UI/DataFields/DateTimePicker/views'

type Props = {
  dispatchDeliveryOrder: DispatchDeliveryOrderViewDTO
  documentationRow: DocumentationActivityRow
  handleRowClick: (event: React.MouseEvent) => void
}

export const DocumentationRow = (props: Props) => {
  const { documentationRow, handleRowClick } = props
  const documentationActivity = documentationRow.activityGroup.documentationActivity
  const streetTurnStatusColor = ddoDirectory.streetTurnStatusColor[documentationActivity.status]

  return (
    <StyledActivityRow
      data-id={'documentation-activity'}
      className={'activity-group-row'}
      style={{
        backgroundColor: `${activityStageColor.rgba(documentationRow.stage, 0.05)}`
      }}
      // @ts-ignore
      onClick={handleRowClick}
    >
      <ColorLine
        style={{
          backgroundColor: `${activityStageColor[documentationRow.stage]}`,
          width: 16
        }}
      />
      <DabbleColumn style={{ width: 330, padding: '0 40px', justifyContent: 'center' }}>
        <ActivityColumn
          style={{ fontWeight: 400 }}
          data-id={[
            'documentation-activity-ddoNumber',
            'ddoId-' + documentationActivity.matchedDDOId,
            'ddoNumber-' + documentationActivity.matchedDDONumber
          ].join(' ')}
        >
          DDO#
          <DDOLink
            onClick={() =>
              openDDOinNewTab({
                ddoId: documentationActivity.matchedDDOId,
                ddoNumber: documentationActivity.matchedDDONumber
              })
            }
          >
            {documentationActivity.matchedDDONumber}
          </DDOLink>
        </ActivityColumn>
        {documentationActivity.matchedContainerNumber && (
          <ActivityColumn
            style={{ fontWeight: 400 }}
            data-id={['documentation-activity-containerNumber', documentationActivity.matchedContainerNumber].join(' ')}
          >
            Container #{documentationActivity.matchedContainerNumber}
          </ActivityColumn>
        )}
      </DabbleColumn>
      <TypeColumn style={{ color: streetTurnStatusColor }}>
        <i className={'mdi mdi-shuffle-variant'} />
        <span data-id={'documentation-activity-type'}>Street Turn</span>
      </TypeColumn>
      <div style={{ border: 'none' }} />
      <Status
        data-id={'documentation-activity-status'}
        style={{
          color: streetTurnStatusColor
        }}
      >
        {documentationActivity.status.toString().toLowerCase()}
      </Status>
      <DabbleColumn />
      <DabbleColumn style={{ padding: 10, justifyContent: 'center' }}>
        <div data-id={['documentation-activity-startActualDate', documentationActivity.startActualDate].join(' ')}>
          {oc(documentationRow).activityGroup.documentationActivity.startActualDate() && (
            // <DateTimePicker disabled={true} date={documentationActivity.startActualDate} onChange={() => {}} />
            <DateField
              disabled={true}
              showTime={true}
              setTimeNowOnEmpty={true}
              placeholder={'MM/DD/YY'}
              date={documentationActivity.startActualDate}
              dateType={DateTypes.DateISOString}
              onUpdate={() => {}}
            />
          )}
        </div>
      </DabbleColumn>
      <DabbleColumn style={{ padding: 10, justifyContent: 'center' }}>
        <div
          data-id={['documentation-activity-completionActualDate', documentationActivity.completionActualDate].join(
            ' '
          )}
        >
          {oc(documentationActivity).completionActualDate() && (
            // <DateTimePicker disabled={true} date={documentationActivity.completionActualDate} onChange={() => {}} />
            <DateField
              disabled={true}
              showTime={true}
              setTimeNowOnEmpty={true}
              placeholder={'MM/DD/YY'}
              date={documentationActivity.completionActualDate}
              dateType={DateTypes.DateISOString}
              onUpdate={() => {}}
            />
          )}
        </div>
      </DabbleColumn>
      <div />
      <div />
      <div />
      <div />
    </StyledActivityRow>
  )
}

const TypeColumn = styled.div`
  width: 220px;
  display: flex;
  align-items: center;
  font-weight: 500;
  padding: 10px;

  i {
    font-weight: 400;
    font-size: 24px;
    margin-right: 15px;
    transform: rotate(-90deg);
  }
`

const DDOLink = styled.div`
  font-weight: 500;
  color: #4555d0;
  user-select: none;
  cursor: pointer;
  margin-left: 4px;
`

const Status = styled(DabbleColumn)`
  justify-content: center;
  text-transform: capitalize;
  padding: 7px 10px;
`
