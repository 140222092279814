import * as React from 'react'
import styled from 'styled-components'
import theme from '../../../styles/theme'

type Props = {
  title?: string
  message?: string
  messages?: string[]
  children?: any
  mdi?: string
  color?: string
  zIndex?: number
  width?: number | string
  style?: React.CSSProperties
}

export const FieldWarning = React.memo((props: Props) => {
  const { title, message, messages, children, mdi, color, zIndex, width, style } = props

  return (
    <Container className={'hint'} style={style}>
      <Icon style={{ color, zIndex }} className={`mdi ${mdi || 'mdi-alert-circle'}`} />
      <Popover style={{ width }}>
        {Boolean(title) && <Title>{title}</Title>}
        {children || message}
        {Boolean(messages && messages.length) && messages.map((text, i) => <Message key={i}>• {text}</Message>)}
      </Popover>
    </Container>
  )
})

const Icon = styled.span`
  display: block;
  color: ${theme.colors.defaultRed};
  font-size: 16px;
  cursor: pointer;
`
const Popover = styled.div`
  width: 200px;
  font-size: 14px;
  line-height: 1.2;
  white-space: pre-wrap;
  display: none;
  position: absolute;
  bottom: 100%;
  left: 10px;
  background-color: white;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.5);
  border-radius: 5px 5px 5px 0;
  padding: 10px;
`
const Title = styled.div`
  font-weight: 500;
  margin-bottom: 10px;
`
const Message = styled.div`
  line-height: 1.2;
`
const Container = styled.div`
  z-index: 5;
  position: relative;
  height: 0;
  width: 0;

  &:hover {
    ${Popover} {
      display: block;
    }
  }
`
