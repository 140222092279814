import { oc } from 'ts-optchain'
import { ConfirmedLocalDateTimeRangeDTO, DateTimeRangeDTO } from '../../../api/api'
import { isDatesEqual } from './isDatesEqual'

type Props = {
  dateRange1: ConfirmedLocalDateTimeRangeDTO | DateTimeRangeDTO | null | undefined
  dateRange2: ConfirmedLocalDateTimeRangeDTO | DateTimeRangeDTO | null | undefined
  skipConfirmationCheck?: boolean
}

export const isDateRangesEqual = (props: Props): boolean => {
  const { dateRange1, dateRange2, skipConfirmationCheck } = props

  if (!dateRange1 && !dateRange2) {
    return true
  }

  if (!dateRange1 || !dateRange2) {
    return false
  }

  return (
    isDatesEqual(dateRange1.from, dateRange2.from) &&
    isDatesEqual(dateRange1.to, dateRange2.to) &&
    (skipConfirmationCheck ||
      oc(dateRange1 as ConfirmedLocalDateTimeRangeDTO).confirmed() ===
        oc(dateRange2 as ConfirmedLocalDateTimeRangeDTO).confirmed())
  )
}
