import { SchedulerSortBy } from '../interfaces'

export const groupTitleWithoutDataMapping: Record<SchedulerSortBy, string> = {
  [SchedulerSortBy.deliveryStageDriver]: 'DDO: No Driver Assigned on Delivery Stage',
  [SchedulerSortBy.returnStageDriver]: 'DDO: No Driver Assigned on Return Stage',
  [SchedulerSortBy.date]: 'Other',
  [SchedulerSortBy.deliveryLocationCity]: 'Other',
  [SchedulerSortBy.driverAllStages]: 'Other',
  [SchedulerSortBy.driverActivities]: 'Other'
}
