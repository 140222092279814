import { APPLIED_LOCALE } from '../../config'
import { DateTimeRangeDTO } from '../../api/origin/business-logic'
import { whatIsIt } from '../functions/test/whatIsIt'

export const twoDigit = (value: number | string): string => {
  return String(value).length < 2 ? ('0' + value).slice(-2) : String(value)
}
const addDateTimeIfNeeded = (date: string) => {
  return date && !date.includes('T') ? date + 'T00:00:00.000' : date
}

export type TDateServiceMakeLabelOptions = {
  long?: boolean
  fullYear?: boolean
  hideYear?: boolean
  hideWeekday?: boolean
  onlyTime?: boolean
  monthName?: 'short' | 'long'
  hour12?: boolean
  hideTime?: boolean
  shortSpace?: boolean
}

export const dateService = {
  makeLabel(date: string | DateTimeRangeDTO, options?: TDateServiceMakeLabelOptions): string {
    const { fullYear, hideYear, hideWeekday, onlyTime, monthName, hour12, hideTime, shortSpace, long } = options || {}

    try {
      if (!date) {
        return ''
      }

      const dateLabel = (_date: Date): string => {
        const getDay = (): string =>
          _date.toLocaleString(APPLIED_LOCALE, {
            month: monthName || '2-digit',
            day: '2-digit',
            year: hideYear ? undefined : fullYear ? 'numeric' : '2-digit'
          })

        if (hideTime) {
          return getDay()
        }

        const space = shortSpace ? ' ' : ' '

        const formatAMPM = () => {
          let hours = _date.getHours()
          const minutes = twoDigit(_date.getMinutes())
          const ampm = hours >= 12 ? 'pm' : 'am'
          hours = hours % 12
          return twoDigit(hours) + ':' + minutes + ' ' + ampm
        }

        const time = hour12 ? formatAMPM() : twoDigit(_date.getHours()) + ':' + twoDigit(_date.getMinutes())

        if (onlyTime) {
          return time
        }

        return getDay() + space + time
      }

      if (whatIsIt(date).isString) {
        date = addDateTimeIfNeeded(date as string)

        return long
          ? new Date(date as string).toLocaleString(APPLIED_LOCALE, {
              weekday: hideWeekday ? undefined : 'short',
              day: '2-digit',
              month: 'short',
              year: fullYear ? 'numeric' : undefined
            })
          : dateLabel(new Date(date as string))
      } else if (whatIsIt(date).isObject) {
        if (date && (date as DateTimeRangeDTO).from) {
          let result = dateLabel(new Date((date as DateTimeRangeDTO).from))

          if ((date as DateTimeRangeDTO).to) {
            const dateTo = new Date((date as DateTimeRangeDTO).to)
            result += ` - ${twoDigit(dateTo.getHours())}:${twoDigit(dateTo.getMinutes())}`
          }

          return result
        }
        return ''
      }

      return ''
    } catch (e) {
      // tslint:disable-next-line:no-console
      console.error(e)
      return ''
    }
  },
  makeDurationLabel(_date1: string | Date, _date2?: string | Date): string {
    try {
      if (!_date1) {
        return ''
      }

      const date1 = typeof _date1 === 'string' ? dateService.convertFromServer(_date1) : _date1
      const date2 = _date2 ? (typeof _date2 === 'string' ? dateService.convertFromServer(_date2) : _date2) : new Date()
      return dateService.makeTimerLabel(Math.abs(date1.getTime() - date2.getTime()))
    } catch (e) {
      // tslint:disable-next-line:no-console
      console.error(e)
      return ''
    }
  },
  makeTimerLabel(duration: number): string {
    if (!duration) {
      return ''
    }

    const minutes = Math.trunc((duration / (1000 * 60)) % 60)
    // const hours = Math.trunc((duration / (1000 * 60 * 60)) % 24)
    const hours = Math.trunc(duration / (1000 * 60 * 60))
    // const days = duration / (1000 * 60 * 60 * 24)
    // return `${twoDigit(hours)}:${twoDigit(minutes)}:${twoDigit(seconds)}`
    return [hours && twoDigit(hours) + 'h', twoDigit(minutes) + 'min'].filter(Boolean).join(' ')
  },
  convertToServer(_date: Date): string {
    if (!_date) {
      return undefined
    }

    const fullYear = _date.getFullYear()
    const month = twoDigit(_date.getMonth() + 1)
    const day = twoDigit(_date.getDate())

    const hours = twoDigit(_date.getHours())
    const minutes = twoDigit(_date.getMinutes())
    const seconds = twoDigit(_date.getSeconds())

    const date = [fullYear, month, day].join('-')
    const time = [hours, minutes, seconds].join(':')

    return `${date}T${time}.000`
  },
  convertFromServer(_date: string | DateTimeRangeDTO): Date {
    try {
      if (!_date) {
        return undefined
      }

      return typeof _date === 'string' ? new Date(addDateTimeIfNeeded(_date)) : _date.from && new Date(_date.from)
    } catch (e) {
      // tslint:disable-next-line:no-console
      console.error(e)
      return undefined
    }
  },
  createDate: {
    get now(): Date {
      const date = new Date()
      return new Date(new Date().setHours(date.getHours(), date.getMinutes(), 0, 0))
    },
    get startDay(): Date {
      return new Date(new Date().setHours(0, 0, 0, 0))
    },
    get endDay(): Date {
      return new Date(new Date().setHours(23, 59, 59, 0))
    },
    get startWorkDay(): Date {
      return new Date(new Date().setHours(8, 0, 0, 0))
    }
  },
  createStringDate: {
    get now(): string {
      return dateService.convertToServer(dateService.createDate.now)
    },
    get startDay(): string {
      return dateService.convertToServer(dateService.createDate.startDay)
    },
    get endDay(): string {
      return dateService.convertToServer(dateService.createDate.endDay)
    },
    get startWorkDay(): string {
      return dateService.convertToServer(dateService.createDate.startWorkDay)
    }
  },
  convertDate(_date: Date) {
    return {
      get now(): Date {
        return dateService.createDate.now
      },
      get startDay(): Date {
        return _date ? new Date(_date.setHours(0, 0, 0, 0)) : _date
      },
      get endDay(): Date {
        return _date ? new Date(_date.setHours(23, 59, 59, 0)) : _date
      },
      get startWorkDay(): Date {
        return _date ? new Date(_date.setHours(8, 0, 0, 0)) : _date
      }
    }
  },
  convertStringDate(_date: string) {
    const date: Date = _date ? new Date(_date) : undefined

    return {
      get now(): string {
        return dateService.convertToServer(dateService.createDate.now)
      },
      get startDay(): string {
        return date ? dateService.convertToServer(new Date(date.setHours(0, 0, 0, 0))) : _date
      },
      get endDay(): string {
        return date ? dateService.convertToServer(new Date(date.setHours(23, 59, 59, 0))) : _date
      },
      get startWorkDay(): string {
        return date ? dateService.convertToServer(new Date(date.setHours(8, 0, 0, 0))) : _date
      }
    }
  },
  getStringDay(_date: string | Date): string {
    if (!_date) {
      return undefined
    }

    const date = typeof _date === 'string' ? addDateTimeIfNeeded(_date) : dateService.convertToServer(_date)

    return date.split('T')[0]
  },
  getStringYear(_date: string | Date): string {
    if (!_date) {
      return undefined
    }

    const date = typeof _date === 'string' ? _date : dateService.convertToServer(_date)

    return date.split('-')[0]
  },
  moveDate(
    _date: string | Date,
    options: {
      addMinutes?: number
      addHours?: number
      addSeconds?: number
      addMonth?: number
      addDay?: number
      addYears?: number
    }
  ): string {
    if (!_date) {
      return undefined
    }

    const { addYears, addDay, addHours, addMinutes, addMonth, addSeconds } = options
    const date = typeof _date === 'string' ? new Date(addDateTimeIfNeeded(_date)) : _date

    if (addSeconds) {
      date.setSeconds(date.getSeconds() + addSeconds)
    }
    if (addHours) {
      date.setHours(date.getHours() + addHours)
    }
    if (addMinutes) {
      date.setMinutes(date.getMinutes() + addMinutes)
    }
    if (addMonth) {
      date.setMonth(date.getMonth() + addMonth)
    }
    if (addDay) {
      date.setDate(date.getDate() + addDay)
    }
    if (addYears) {
      date.setFullYear(date.getFullYear() + addYears)
    }

    return dateService.convertToServer(date)
  },
  isCurrentDay(_date: string | Date): boolean {
    return dateService.getStringDay(_date) === dateService.getStringDay(new Date())
  },
  isCurrentYear(_date: string | Date): boolean {
    return dateService.getStringYear(_date) === dateService.getStringYear(new Date())
  },
  isTheSameDay(_date1: string | Date, _date2: string | Date): boolean {
    return dateService.getStringDay(_date1) === dateService.getStringDay(_date2)
  },
  isTheSameYear(_date1: string | Date, _date2: string | Date): boolean {
    return dateService.getStringYear(_date1) === dateService.getStringYear(_date2)
  },
  isTimeFilled(_date: string | Date): boolean {
    const date = typeof _date === 'string' ? new Date(addDateTimeIfNeeded(_date)) : _date

    if (!date) {
      return false
    }

    return date.getHours() !== 0 || date.getMinutes() !== 0
  }
}
