import * as React from 'react'
import { oc } from 'ts-optchain'
import ReactTooltip from 'react-tooltip'
import { DriverDTO, DriverViewDTO } from '../../../../../api/origin/business-logic/'
import { AvailabilityColumnSelecter } from '../../../../UI/AvailabilitySelecter'
import { CandidateStatusColumn, RejectButton } from './CandidateStatusColumn'
import { ArrowActionButton } from '../../../../UI/Buttons/ArrowActionButton'
import { driverDirectory } from '../../../../../services/DTO/driver/directory'
import { preventEvent } from '../../../../../services/functions/basic'
import { driverStatusColumnHintId } from '../../../../Hints/DriverStatusColumnHint'
import { groupedDriverStatuses } from '../../../../../constants/driverStatuses'
import { showWarningModalWindow } from '../../../../../store/reducers/modalWindow/functions'
import { useGridItemDriver } from '../../../../../hooks/useGridItemDriver'
import { useExpandedItem } from '../../../../../hooks/useExpandedItem'

type Props = {}

export const AvailabilityColumn = React.memo((props: Props) => {
  const { driver, isExpanded } = useGridItemDriver()
  // @ts-ignore
  const { modifyParentObjectField, isModified } = isExpanded ? useExpandedItem() : {}
  const isVendor = driver.driverType === DriverViewDTO.DriverTypeEnum.VENDOR
  const isOutsource = driver.driverType === DriverViewDTO.DriverTypeEnum.OUTSOURCE
  const attributes =
    !isExpanded && driver && driver.notes
      ? { 'data-for': driverStatusColumnHintId, 'data-tip': driver.notes }
      : undefined
  const updateStatus = isExpanded
    ? (status: DriverViewDTO.StatusEnum) => modifyParentObjectField('status')(status)
    : undefined

  React.useEffect(() => {
    ReactTooltip.rebuild()
  }, [oc(driver).notes()])

  React.useEffect(() => {
    ReactTooltip.hide()
  }, [isExpanded])

  const list = React.useMemo(() => {
    const makeItems = (_statuses: DriverDTO.StatusEnum[]) =>
      _statuses.map(item => ({ label: driverDirectory.status[item], value: item }))

    if (isVendor || isOutsource) {
      return [
        {
          title: 'Active',
          items: makeItems([DriverDTO.StatusEnum.AVAILABLE])
        },
        {
          title: 'Inactive',
          items: makeItems([DriverDTO.StatusEnum.TERMINATED])
        }
      ]
    } else if (driver.status === DriverDTO.StatusEnum.TERMINATED) {
      return [
        {
          title: 'Inactive',
          items: makeItems([DriverDTO.StatusEnum.TERMINATED])
        },
        {
          title: 'Candidate',
          items: makeItems(groupedDriverStatuses.Candidate)
        }
      ]
    }

    return [
      {
        title: 'Active',
        items: makeItems(groupedDriverStatuses.Active)
      },
      {
        title: 'Inactive',
        items: makeItems(groupedDriverStatuses.Inactive)
      }
    ]
  }, [driver.driverType, driver.status])

  React.useEffect(() => {
    if (isModified && !driverDirectory.sortedCandidateStatuses.includes(driver.status)) {
      const driverStatuses: DriverDTO.StatusEnum[] = []

      Object.values(list).forEach(_ =>
        _.items.forEach(item => {
          driverStatuses.push(item.value)
        })
      )

      if (!driverStatuses.includes(driver.status)) {
        modifyParentObjectField('status')(driverStatuses[0])
      }
    }
  })

  if (
    driverDirectory.sortedCandidateStatuses
      .filter(_ => _ !== DriverViewDTO.StatusEnum.CANDIDATEAPPROVED)
      .includes(driver.status)
  ) {
    return <CandidateStatusColumn attributes={attributes} />
  }

  return (
    <AvailabilityColumnSelecter
      disabled={!isExpanded}
      attributes={attributes}
      onSelect={status => {
        if (status === DriverViewDTO.StatusEnum.TERMINATED) {
          return showWarningModalWindow({
            width: 310,
            title: `Are you sure you want\n to terminate the driver?`,
            buttons: [{ label: 'Cancel', outline: true }, { label: 'Confirm', onClick: () => updateStatus(status) }]
          })
        }

        updateStatus(status)
      }}
      selected={driver.status}
      list={list}
      labelElement={
        driver.status === DriverViewDTO.StatusEnum.CANDIDATEAPPROVED
          ? ({ containerRef, togglePopover }) => {
              return (
                <div
                  ref={containerRef}
                  style={updateStatus ? { display: 'grid', gridTemplateColumns: 'auto 1fr' } : undefined}
                >
                  {updateStatus && (
                    <RejectButton
                      children={'Reject'}
                      style={{ marginRight: 8 }}
                      onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                        preventEvent(event)
                        return updateStatus(DriverViewDTO.StatusEnum.CANDIDATEREJECTED)
                      }}
                    />
                  )}
                  <ArrowActionButton
                    text={driverDirectory.status[driver.status]}
                    color={driverDirectory.statusColor[driver.status]}
                    attributes={attributes}
                    onLeftArrowClick={
                      updateStatus
                        ? (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                            preventEvent(event)
                            return updateStatus(DriverViewDTO.StatusEnum.CANDIDATEREVIEW)
                          }
                        : undefined
                    }
                    onRightArrowClick={updateStatus ? togglePopover : undefined}
                  />
                </div>
              )
            }
          : undefined
      }
    />
  )
})
