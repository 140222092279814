import * as React from 'react'
import { oc } from 'ts-optchain'
import theme from '../../../../../styles/theme'
import { NoWrap, TableCell } from '../../../grid/views/styles'
import { TGridColumn } from '../../../../../store/reducers/tabs/interfaces'
import { useGridItemDDO } from '../../../../../hooks/useGridItemDDO'
import { DispatchDeliveryOrderColumn } from '../../../grid/columnSettings/dispatchDeliveryOrderColumnSettings'
import { NotificationsColumn } from './NotificationsColumn'
import { dateService } from '../../../../../services/timeService'
import { DispatchDeliveryOrderNumberColumn } from './DispatchDeliveryOrderNumberColumn'
import { DeliveryOrderNumberColumn } from './DeliveryOrderNumberColumn'
import { StatusColumn } from './StatusColumn'
import { deliveryOrderDirectory } from '../../../../../services/DTO/deliveryOrder/directory'
import { ContainerColumn } from './ContainerColumn'
import { ddoDirectory } from '../../../../../services/DTO/dispatchDeliveryOrder/directory'
import { DeliveryOrderViewDTO } from '../../../../../api/api'
import { ShuttleLocationColumn } from './ShuttleLocationColumn'
import { isLocationGEOValid } from '../../../../../services/functions/test/isLocationGEOValid'
import { getStateMapping } from '../../../../../store'
import { locationTypeIcons } from '../../../../../services/DTO/location'
import { activityDirectory } from '../../../../../services/DTO/activity/directory'
import { AppDateConfirmedIndicator } from '../Details/General/Center'
import { StreetTurnColumn } from './StreetTurnColumn'
import { convertISODateToDateWithHoursMins } from '../../../../../services/timeService/dateUtils'

const Column = (props: TGridColumn) => {
  const { className, columnName } = props
  const {
    dispatchDeliveryOrder,
    deliveryOrder,
    containerType,
    container,
    steamShipLine,
    customer,
    customerBusinessPartner,
    equipment,
    pickupLocation,
    returnLocation,
    pickupDriver,
    returnDriver,
    deliveryActivity,
    deliveryActivityLocation,
    deliveryActivityDriver
  } = useGridItemDDO()
  let text = columnName
  let style = props.style

  switch (columnName) {
    case DispatchDeliveryOrderColumn.Notifications: {
      return (
        <TableCell className={className} style={{ ...(style || {}), padding: 0 }}>
          <div style={{ display: 'flex', alignSelf: 'stretch' }}>
            <NotificationsColumn />
          </div>
        </TableCell>
      )
    }
    case DispatchDeliveryOrderColumn.DOCreated: {
      text = dateService.makeLabel(oc(deliveryOrder).date())
      break
    }
    case DispatchDeliveryOrderColumn.DDONumber: {
      return (
        <TableCell className={className} data-copy-value-on-mousedown={true} style={style}>
          <DispatchDeliveryOrderNumberColumn />
        </TableCell>
      )
    }
    case DispatchDeliveryOrderColumn.DONumber: {
      return (
        <TableCell
          className={className}
          data-copy-value-on-mousedown={true}
          data-copy-value={oc(deliveryOrder).number()}
          style={style}
        >
          <DeliveryOrderNumberColumn />
        </TableCell>
      )
    }
    case DispatchDeliveryOrderColumn.Status: {
      return (
        <TableCell className={className} style={style} data-copy-value-on-mousedown={true}>
          <StatusColumn />
        </TableCell>
      )
    }
    case DispatchDeliveryOrderColumn.Type: {
      text = deliveryOrderDirectory.columnType[deliveryOrder.type]
      style = { ...style, textTransform: 'uppercase', fontWeight: 500, letterSpacing: '1.5px' }
      break
    }
    case DispatchDeliveryOrderColumn.Equipment: {
      text = oc(containerType).code()
      break
    }
    case DispatchDeliveryOrderColumn.Container: {
      return (
        <TableCell
          className={className}
          data-copy-value-on-mousedown={true}
          data-copy-value={oc(container).number()}
          style={style}
        >
          <ContainerColumn />
        </TableCell>
      )
    }
    case DispatchDeliveryOrderColumn.StreetTurn: {
      return (
        <TableCell className={className} data-copy-value-on-mousedown={true} style={style}>
          <StreetTurnColumn />
        </TableCell>
      )
      break
    }
    case DispatchDeliveryOrderColumn.SSL: {
      text = oc(steamShipLine).name()
      break
    }
    case DispatchDeliveryOrderColumn.LoadType: {
      text = ddoDirectory.loadType[oc(dispatchDeliveryOrder).loadType()]
      break
    }
    case DispatchDeliveryOrderColumn.BookingBL: {
      text =
        oc(deliveryOrder).type() === DeliveryOrderViewDTO.TypeEnum.EXPORT ||
        oc(deliveryOrder).type() === DeliveryOrderViewDTO.TypeEnum.REPOSITION
          ? deliveryOrder.bookingNumber
          : deliveryOrder.billOfLadingNumber
      break
    }
    case DispatchDeliveryOrderColumn.Customer: {
      text = oc(customerBusinessPartner).legalName() || oc(customer).name()
      break
    }
    case DispatchDeliveryOrderColumn.Chassis: {
      text = oc(equipment).chassisNumber()
      break
    }
    case DispatchDeliveryOrderColumn.Pickup: {
      const shortName = oc(pickupLocation).shortName()

      return (
        <TableCell
          className={className}
          title={shortName}
          style={{ ...style, ...pickupColumnStyles }}
          data-copy-value-on-mousedown={true}
        >
          <ShuttleLocationColumn
            location={pickupLocation}
            isShuttle={oc(dispatchDeliveryOrder).pickupStage.shuttle(false)}
          />
        </TableCell>
      )
    }
    case DispatchDeliveryOrderColumn.PickupDriver: {
      text = oc(pickupDriver).name()
      style = { ...style, ...pickupColumnStyles }
      break
    }
    case DispatchDeliveryOrderColumn.Delivery: {
      if (!deliveryActivityLocation) {
        return (
          <TableCell
            className={className}
            style={{ ...style, ...deliveryColumnStyles }}
            data-copy-value-on-mousedown={true}
          />
        )
      }

      const isInvalid = isLocationGEOValid(deliveryActivityLocation) === false
      const cityState = [
        oc(deliveryActivityLocation).address.city(),
        oc(deliveryActivityLocation).address.stateId() &&
          oc(getStateMapping())[deliveryActivityLocation.address.stateId].code()
      ]
        .filter(Boolean)
        .join(', ')
      const zip = oc(deliveryActivityLocation).address.postalCode()
      const shortNameAddress = [oc(deliveryActivityLocation).shortName(), oc(deliveryActivityLocation).address.street()]
        .filter(Boolean)
        .join(', ')
      const zipShortNameAddress = [zip, shortNameAddress].filter(Boolean).join(', ')

      return (
        <TableCell
          className={className}
          style={{ ...style, ...deliveryColumnStyles, color: isInvalid ? theme.colors.defaultRed : undefined }}
          data-copy-value={oc(deliveryActivityLocation).longName()}
          data-copy-value-on-mousedown={true}
        >
          <div
            style={{ display: 'flex', alignItems: 'center', fontSize: 18 }}
            className={locationTypeIcons[deliveryActivityLocation.type]}
          >
            <NoWrap style={{ marginLeft: 5, fontSize: 12 }}>
              {cityState && <span style={{ fontWeight: 500, fontSize: 14 }}>{cityState}</span>}
              {Boolean(cityState && zipShortNameAddress) && ', '}
              {zipShortNameAddress}
            </NoWrap>
          </div>
        </TableCell>
      )
    }
    case DispatchDeliveryOrderColumn.DeliveryDriver: {
      text = oc(deliveryActivityDriver).name()
      style = { ...style, ...deliveryColumnStyles }
      break
    }
    case DispatchDeliveryOrderColumn.DeliveryActivity: {
      text = activityDirectory.type[oc(deliveryActivity).comboType()]
      style = { ...style, ...deliveryColumnStyles }
      break
    }
    case DispatchDeliveryOrderColumn.ActivityPlanned: {
      text = dateService.makeLabel(oc(deliveryActivity).startPlannedDateTimeRange())
      style = { ...style, ...deliveryColumnStyles }
      break
    }
    case DispatchDeliveryOrderColumn.ActivityCompletion: {
      // text = dateService.makeLabel(oc(deliveryActivity).completionActualDate())
      text = convertISODateToDateWithHoursMins(oc(deliveryActivity).completionActualDate(), false, false)
      style = { ...style, ...deliveryColumnStyles }
      break
    }
    case DispatchDeliveryOrderColumn.PickUpPlanned: {
      text = dateService.makeLabel(oc(dispatchDeliveryOrder).pickupStage.plannedAppointmentDateTimeRange())
      style = { ...style, ...pickupColumnStyles }
      break
    }
    case DispatchDeliveryOrderColumn.Appointment: {
      const confirmed = oc(dispatchDeliveryOrder).deliveryStage.plannedAppointmentDateTimeRange.confirmed()
      // text =
      //   dateService.makeLabel(oc(dispatchDeliveryOrder).deliveryStage.actualAppointmentDate()) ||
      //   dateService.makeLabel(oc(dispatchDeliveryOrder).deliveryStage.plannedAppointmentDateTimeRange())
      text =
        convertISODateToDateWithHoursMins(
          oc(dispatchDeliveryOrder).deliveryStage.actualAppointmentDate(),
          false,
          false
        ) || dateService.makeLabel(oc(dispatchDeliveryOrder).deliveryStage.plannedAppointmentDateTimeRange())

      style = { ...style, ...deliveryColumnStyles, ...(confirmed === false ? { color: theme.colors.defaultRed } : {}) }
      break
    }
    case DispatchDeliveryOrderColumn.ReturnPlanned: {
      text = dateService.makeLabel(oc(dispatchDeliveryOrder).returnStage.plannedAppointmentDateTimeRange())
      style = { ...style, ...returnColumnStyles }
      break
    }
    case DispatchDeliveryOrderColumn.ApptDateStatus: {
      const confirmed = oc(dispatchDeliveryOrder).deliveryStage.plannedAppointmentDateTimeRange.confirmed()
      const disabled = Boolean(oc(dispatchDeliveryOrder).deliveryStage.actualAppointmentDate())

      return (
        <TableCell
          className={className}
          title={text}
          style={{ ...style, ...deliveryColumnStyles }}
          data-copy-value-on-mousedown={true}
        >
          <div style={{ textAlign: 'center' }}>
            <AppDateConfirmedIndicator confirmed={confirmed} disabled={disabled} />
          </div>
        </TableCell>
      )
    }
    case DispatchDeliveryOrderColumn.Return: {
      const shortName = oc(returnLocation).shortName()

      return (
        <TableCell
          className={className}
          title={shortName}
          style={{ ...style, ...returnColumnStyles }}
          data-copy-value-on-mousedown={true}
        >
          <ShuttleLocationColumn
            location={returnLocation}
            isShuttle={oc(dispatchDeliveryOrder).returnStage.shuttle(false)}
          />
        </TableCell>
      )
    }
    case DispatchDeliveryOrderColumn.ReturnDriver: {
      text = oc(returnDriver).name()
      style = { ...style, ...returnColumnStyles }
      break
    }
    case DispatchDeliveryOrderColumn.DaysOut: {
      const daysOut = oc(dispatchDeliveryOrder).daysOut()

      if (Number(daysOut) && Number(daysOut) > 2) {
        style = { ...style, color: theme.colors.defaultRed }
      }
      // @ts-ignore
      text = daysOut
      break
    }
    case DispatchDeliveryOrderColumn.Availability: {
      text = dateService.makeLabel(oc(deliveryOrder).equipmentFirstPickupDate())
      break
    }
    case DispatchDeliveryOrderColumn.CutOffLFD: {
      text = dateService.makeLabel(oc(deliveryOrder).generalCutoffDate())
      break
    }
    case DispatchDeliveryOrderColumn.LFD: {
      text = dateService.makeLabel(oc(deliveryOrder).lastFreeDateDemurrage())
      break
    }
    case DispatchDeliveryOrderColumn.PerDiemFreeBy: {
      text = dateService.makeLabel(oc(deliveryOrder).lastFreeDatePerDiem())
      break
    }
    case DispatchDeliveryOrderColumn.FirstReceiving: {
      text = dateService.makeLabel(oc(deliveryOrder).firstReceivingDate())
      break
    }
    case DispatchDeliveryOrderColumn.ETA: {
      text = dateService.makeLabel(oc(deliveryOrder).vesselArrivalDate())
      break
    }

    default:
  }

  return (
    <TableCell className={className} title={text} style={style} data-copy-value-on-mousedown={true}>
      <div>{text}</div>
    </TableCell>
  )
}

const pickupColumnStyles = {
  background: 'rgba(124, 127, 207, 0.08)'
}
const deliveryColumnStyles = {
  background: 'rgba(193, 121, 209, 0.15)'
}
const returnColumnStyles = {
  background: 'rgba(124, 207, 129, 0.1)'
}

export default Column
