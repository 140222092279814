import * as React from 'react'
import styled from 'styled-components'
import cn from 'classnames'

type Props = {
  header?: (string | JSX.Element)[]
  body: (string | JSX.Element)[][]
  columnWidthEvenly?: boolean
  tableStyles?: React.CSSProperties
}

export const WidgetTable = (props: Props) => {
  const { header, body, columnWidthEvenly, tableStyles } = props
  const columnStyles = columnWidthEvenly && body.length ? getColumnWidthStyle(body[0].length) : undefined

  return (
    <StyledTable style={tableStyles}>
      <tbody>
        {header && (
          <Header>
            {header.map((heading, headingIndex) => (
              <HeaderColumn key={headingIndex} style={columnStyles}>
                {heading}
              </HeaderColumn>
            ))}
          </Header>
        )}
        {body.map((row, rowIndex) => (
          <Row key={rowIndex}>
            {row.map((columnValue, columnIndex) => (
              <Column key={columnIndex} className={cn({ border: Boolean(header) })} style={columnStyles}>
                {columnValue}
              </Column>
            ))}
          </Row>
        ))}
      </tbody>
    </StyledTable>
  )
}

const getColumnWidthStyle = (columnsNumber: number) => {
  return { width: (100 / columnsNumber).toFixed(0) + '%' }
}

const StyledTable = styled.table``
const Header = styled.tr`
  cursor: default;
  //border-bottom: 1px solid #cdcdcd;
`
const Row = styled.tr`
  &:nth-child(2n) {
    background-color: #f5f6fa;
  }
`
const Column = styled.td`
  font-size: 14px;
  line-height: 23px;
  color: #445366;
  white-space: pre-wrap;
  padding: 11px 16px;

  &.border:not(:first-child) {
    border-left: 1px solid #f2f2f2;
  }
`
export const RowTitleColumn = styled.div`
  white-space: nowrap;
  font-weight: 500;
`
const HeaderColumn = styled(Column)`
  position: sticky;
  top: 0;
  border-color: transparent !important;
  font-size: 10px;
  line-height: 12px;
  color: #6c707d;
  background-color: white;

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    height: 1px;
    background-color: #cdcdcd;
  }

  &:not(:first-child):before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    display: block;
    width: 1px;
    height: 20px;
    background-color: #cdcdcd;
  }
`
