import * as React from 'react'
import cn from 'classnames'
import { oc } from 'ts-optchain'
import { DateFieldContainer, DateField, TimeContainer, Separator, ResetButton } from './styles'
import DatePickerComponent from 'react-datepicker'
import { dateService } from '../../../../../services/timeService/'
import { ConfirmedLocalDateTimeRangeDTO, DateTimeRangeDTO } from '../../../../../api/origin/business-logic'
import { TimeInput } from './TimeInput'
import { DateRangeType } from '../types'
import { FieldContainer } from '../../FieldContainer'
import { debuggingMode } from '../../../../../services/debug'
import { Whitespace } from '../../../../../services/keyboardService/keys'

export enum DateOnFocus {
  now = 'now',
  startDay = 'startDate',
  endDay = 'endDate',
  startWorkDay = 'startWorkDay'
}

type Props = {
  date: string | DateTimeRangeDTO
  onChange: (date: string | DateTimeRangeDTO | ConfirmedLocalDateTimeRangeDTO) => void
  minDate?: string
  maxDate?: string
  isRange?: boolean
  dateOnFocus?: DateOnFocus
  required?: boolean
  disabled?: boolean
  title?: string
  redText?: boolean
  highlight?: boolean
  placeholder?: string
  hideTimeInput?: boolean
  ignoreTabPress?: boolean
  hideResetButton?: boolean
  isTimeRequired?: boolean
}

export const DateTimePicker = React.memo((props: Props) => {
  const {
    title,
    date,
    isRange,
    onChange,
    redText,
    dateOnFocus,
    disabled,
    hideResetButton,
    placeholder,
    hideTimeInput,
    required,
    ignoreTabPress,
    highlight,
    isTimeRequired
  } = props
  const [dateObject, setDateObject] = React.useState<Date>(dateService.convertFromServer(date))
  const minDate = React.useMemo(() => dateService.convertFromServer(props.minDate), [props.minDate])
  const maxDate = React.useMemo(() => dateService.convertFromServer(props.maxDate), [props.maxDate])

  React.useEffect(() => {
    setDateObject(dateService.convertFromServer(date))
  }, [date])

  const handleDateChange = React.useCallback(
    (_date: string | DateTimeRangeDTO) => {
      if (debuggingMode.datepicker) {
        // tslint:disable-next-line:no-console
        console.log((title || 'DATE') + ' UPDATED: ', _date)
      }

      return onChange(_date)
    },
    [onChange]
  )

  const onDateInputBlur = React.useCallback(() => {
    const isDateChanged = checkDateChanges(dateObject, date, isRange)

    if (isDateChanged) {
      selectDatePickerDate(dateObject)
    }
  }, [dateObject, date])

  const onKeyDown = React.useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>): void => {
      if (event.key === Whitespace.Tab) {
        if (ignoreTabPress) {
          event.preventDefault()
        }

        onDateInputBlur()
      }
    },
    [dateObject, date]
  )

  const selectDatePickerDate = (_date: Date) => {
    const isDateChanged = checkDateChanges(_date, date, isRange)

    if (!isDateChanged) {
      return
    }

    if (isRange) {
      const result: DateTimeRangeDTO = {
        ...((date as DateTimeRangeDTO) || {}),
        from: undefined,
        to: undefined
      }

      if (!_date) {
        return result
      }

      result.from = dateService.convertToServer(_date)

      if (date && typeof date === 'object' && date.to) {
        const dateRangeTo = dateService.convertFromServer(date.to)
        const hours = dateRangeTo.getHours()
        const minutes = dateRangeTo.getMinutes()

        result.to = dateService.convertToServer(new Date(_date.setHours(hours, minutes)))
      }

      return handleDateChange(result)
    }

    return handleDateChange(dateService.convertToServer(_date))
  }

  const changeTime = (type?: DateRangeType) => (time: { hours: number; minutes: number }) => {
    const { hours, minutes } = time
    const clearDate = type === DateRangeType.to && !hours && !minutes

    const updatedDateTime = clearDate
      ? undefined
      : dateService.convertToServer(new Date(dateObject.setHours(hours || 0, minutes || 0)))

    if (!type) {
      return handleDateChange(updatedDateTime)
    }

    return handleDateChange({
      ...((date as DateTimeRangeDTO) || {}),
      [type]: updatedDateTime
    })
  }

  const setDateOnFocus = () => {
    if (!hasValue && dateOnFocus) {
      const _date = (() => {
        switch (dateOnFocus) {
          case DateOnFocus.now:
            return dateService.createStringDate.now
          case DateOnFocus.startDay:
            return dateService.createStringDate.startDay
          case DateOnFocus.startWorkDay:
            return dateService.createStringDate.startWorkDay
          case DateOnFocus.endDay:
            return dateService.createStringDate.endDay
          default:
        }
      })()

      if (isRange) {
        return handleDateChange({
          ...((date as DateTimeRangeDTO) || {}),
          // @ts-ignore
          from: _date
        })
      }

      // @ts-ignore
      return handleDateChange(_date)
    }
  }

  const hasRangeDate = Boolean(date && typeof date === 'object' && date.from)
  const hasDate = Boolean(date && typeof date === 'string')
  const hasValue = hasRangeDate || hasDate

  return (
    <FieldContainer title={title} required={required} className={'date-time-picker-container'}>
      <DateFieldContainer
        className={cn('date-time-picker', {
          'red-text': redText,
          disabled,
          'red-border':
            highlight ||
            (required && !date) ||
            (isTimeRequired && hasDate && !dateService.isTimeFilled(date as string)),
          empty: !hasValue
        })}
      >
        <DateField>
          {/* @ts-ignore */}
          <DatePickerComponent
            disabled={disabled}
            adjustDateOnChange={false}
            // showMonthDropdown={true}
            // showYearDropdown={true}
            portalId={'date-picker-portal'}
            placeholderText={placeholder || 'MM/DD/YY'}
            // showPopperArrow={false}
            selected={dateObject}
            minDate={minDate || defaultMinDate}
            maxDate={maxDate || defaultMaxDate}
            onSelect={selectDatePickerDate}
            onChange={setDateObject as (date: Date) => void}
            dateFormat={'MM/dd/yy'}
            onBlur={onDateInputBlur}
            onFocus={setDateOnFocus}
            // @ts-ignore
            onKeyDown={onKeyDown}
          />
        </DateField>
        {hasValue && !hideTimeInput && (
          <TimeContainer>
            {hasRangeDate && (
              <>
                <TimeInput
                  disabled={disabled}
                  date={(date as DateTimeRangeDTO).from}
                  onChange={changeTime(DateRangeType.from)}
                />
                <Separator hidden={disabled && !(date as DateTimeRangeDTO).to} />
                {disabled && !(date as DateTimeRangeDTO).to ? null : (
                  <TimeInput
                    disabled={disabled}
                    date={(date as DateTimeRangeDTO).to}
                    onChange={changeTime(DateRangeType.to)}
                  />
                )}
              </>
            )}
            {hasDate && (
              <TimeInput required={isTimeRequired} disabled={disabled} date={date as string} onChange={changeTime()} />
            )}
          </TimeContainer>
        )}
        {!hideResetButton && hasValue && !disabled && (
          <ResetButton
            className={'mdi mdi-close-circle'}
            onClick={() => {
              if (isRange) {
                return handleDateChange({
                  ...((date as DateTimeRangeDTO) || {}),
                  from: undefined,
                  to: undefined
                })
              }

              return handleDateChange(undefined)
            }}
          />
        )}
      </DateFieldContainer>
    </FieldContainer>
  )
})

const defaultMinDate = dateService.convertFromServer(
  dateService.moveDate(dateService.createDate.now, { addYears: -100 })
)
const defaultMaxDate = dateService.convertFromServer(
  dateService.moveDate(dateService.createDate.now, { addYears: 100 })
)

const checkDateChanges = (newDate: Date, prevDate: string | DateTimeRangeDTO, isRange: boolean): boolean => {
  return isRange
    ? dateService.convertToServer(newDate) !== oc(prevDate as DateTimeRangeDTO).from()
    : dateService.convertToServer(newDate) !== prevDate
}
