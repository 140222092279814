import * as React from 'react'
import styled from 'styled-components'
import { oc } from 'ts-optchain'
import {
  // ContainerTypeDTO,
  // DateISOString,
  DispatchDeliveryOrderViewDTO
  // EquipmentDTO
} from '../../api/api'
import { compareDates } from '../../services/timeService/dateUtils'
import { requestDispatchDeliveryOrderById } from '../common/dispatchDeliveryOrder/epics'
import { requestEquipmentById } from '../common/equipment/epics'
// import { IEquipment } from '../common/equipment/interfaces'
import { FieldContainer } from '../UI/DataFields/FieldContainer'
import { Input } from '../UI/DataFields/Input'
import { ContainerSearch, EquipmentSearch } from '../UI/DataFields/SearchRequest'
import { FieldTemplate } from '../UI/DataFields/Templates'
import { showModalWindow, closeModalWindow } from '../../store/reducers/modalWindow/functions'
import { GridSpinner } from '../UI/Spinner/Spinner'
import { StreetTurnTable } from './StreetTurnTable'
import { isNewObject } from '../../services/DTO'
import { useDispatchDeliveryOrder } from '../../hooks/useDispatchDeliveryOrder'
import { useEquipment } from '../../hooks/useEquipment'
import { useDeliveryOrder } from '../../hooks/useDeliveryOrder'
import { isContainerRequiredForDDO } from '../../services/DTO/dispatchDeliveryOrder/functions'
import { useStreetTurn } from '../../hooks/useStreetTurn'
import { isFullObject } from '../../services/functions/test/isFullObject'
import { DateTimePicker } from '../UI/DataFields/DateTimePicker/views'

type Props = { dispatchDeliveryOrderId: string }

export const DispatchDeliveryOrderEquipmentPopupContent = React.memo((props: Props) => {
  const { dispatchDeliveryOrderId } = props
  const dispatchDeliveryOrder = useDispatchDeliveryOrder({ id: dispatchDeliveryOrderId })
  const equipment = useEquipment({ id: oc(dispatchDeliveryOrder).equipmentId() })
  const deliveryOrder = useDeliveryOrder({ id: oc(dispatchDeliveryOrder).deliveryOrderId() })
  const streetTurn = useStreetTurn({ id: oc(dispatchDeliveryOrder).streetTurnId() })
  const [fetching, setFetching] = React.useState(false)

  React.useEffect(() => {
    let mounted = true
    new Promise<DispatchDeliveryOrderViewDTO>(async resolve => {
      let resolvedDispatchDeliveryOrder = dispatchDeliveryOrder

      if (!dispatchDeliveryOrder) {
        if (mounted) {
          setFetching(true)
        }
        resolvedDispatchDeliveryOrder = await requestDispatchDeliveryOrderById(dispatchDeliveryOrderId)
      }

      resolve(resolvedDispatchDeliveryOrder)
    })
      .then(async resolvedDispatchDeliveryOrder => {
        if (
          resolvedDispatchDeliveryOrder &&
          resolvedDispatchDeliveryOrder.equipment &&
          !isFullObject(resolvedDispatchDeliveryOrder.equipment)
        ) {
          if (mounted) {
            setFetching(true)
          }
          await requestEquipmentById(equipment.id)
        }
      })
      .finally(() => {
        if (mounted) {
          setFetching(false)
        }
      })

    return () => {
      mounted = false
    }
  }, [])

  if (!dispatchDeliveryOrder) {
    return <GridSpinner />
  }

  const days = oc(equipment).days()
  const pickupDate = oc(equipment).pickupDate()
  const returnDate = oc(equipment).returnDate()

  const isNewChassis = isNewObject(oc(equipment)())

  const estimate = {
    calcDates: pickupDate && returnDate ? Math.ceil(Number(compareDates(pickupDate, returnDate, 'days'))) : '',
    placeholder: isNewChassis ? 'MM/DD/YYYY' : ' '
  }

  return (
    <>
      {fetching && <GridSpinner />}
      <Fields>
        <div>
          <FieldTemplate.ContainerTypeSearch
            id={dispatchDeliveryOrder.containerTypeId}
            disabled={true}
            title={'Container type'}
            required={true}
            onChange={undefined}
          />
        </div>
        <div>
          <ContainerSearch
            disabled={true}
            required={isContainerRequiredForDDO(deliveryOrder.type, dispatchDeliveryOrder.status)}
            title={'Container #'}
            placeholder={dispatchDeliveryOrder.containerNumberPlaceholder}
            onChange={undefined}
            id={oc(dispatchDeliveryOrder).containerId()}
            steamShipLineId={oc(deliveryOrder).steamShipLineId()}
            containerTypeId={dispatchDeliveryOrder.containerTypeId}
          />
        </div>
        <div>
          <Input
            disabled={true}
            title={'Seal'}
            maxLength={20}
            value={dispatchDeliveryOrder.sealNumber}
            onChange={undefined}
          />
        </div>
        <div>
          <EquipmentSearch
            disabled={true}
            required={false}
            allowCreate={true}
            title={'Chassis #'}
            equipmentId={dispatchDeliveryOrder.equipmentId}
            onChange={undefined}
          />
        </div>
        <div>
          <DateTimePicker
            title={'Chassis Pickup'}
            disabled={true}
            date={pickupDate}
            placeholder={estimate.placeholder}
            onChange={() => {}}
          />
        </div>
        <div>
          <DateTimePicker
            title={'Chassis Return'}
            disabled={true}
            date={returnDate}
            placeholder={estimate.placeholder}
            onChange={() => {}}
          />
        </div>
        <div>
          <FieldContainer wrapChild={true} title={'Chassis Days'} childrenStyle={{ height: 35, fontSize: 13 }}>
            {days || estimate.calcDates}
          </FieldContainer>
        </div>
      </Fields>

      {!dispatchDeliveryOrder.troubleTicketId && (
        <StreetTurnTable
          streetTurn={streetTurn}
          dispatchDeliveryOrder={dispatchDeliveryOrder}
          deliveryOrder={deliveryOrder}
          setFetching={setFetching}
          onLinkClick={closeModalWindow}
        />
      )}
    </>
  )
})

export const showDispatchDeliveryOrderEquipmentPopup = (props: { dispatchDeliveryOrderId: string; title?: string }) => {
  const { dispatchDeliveryOrderId, title } = props

  showModalWindow({
    title: title || 'Equipment',
    content: <DispatchDeliveryOrderEquipmentPopupContent dispatchDeliveryOrderId={dispatchDeliveryOrderId} />,
    buttons: [
      {
        label: 'Close'
      }
    ]
  })
}

const Fields = styled.div`
  width: 1012px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 -16px;

  > div {
    width: 33%;
    padding: 0 16px;
    margin-bottom: 24px;
  }
`
