import * as React from 'react'
import { Checkbox } from '../../../../UI/Radio'
import { oc } from 'ts-optchain'
import styled from 'styled-components'
import ReactTooltip from 'react-tooltip'
import { TestDatepickerComponent } from './TestDatepicker'
import { DebugModeField } from '../../../../../services/debug'
import { TestDriverActivity } from './TestDriverActivity'
import { TestSentry } from './TestSentry'

export type TDebugMode = Record<DebugModeField, boolean>

const initialState: TDebugMode = {
  [DebugModeField.simplifyActivityDateValidation]: false,
  [DebugModeField.common]: false,
  [DebugModeField.hideCommunicationHub]: false,
  [DebugModeField.websoket]: false,
  [DebugModeField.detailsOnClick]: false,
  [DebugModeField.expandedGridItem]: false,
  [DebugModeField.scheduler]: false,
  [DebugModeField.datepicker]: false,
  [DebugModeField.driverAssign]: false,
  [DebugModeField.diverActivity]: false,
  [DebugModeField.testDriver]: false
}

const hints = {
  [DebugModeField.simplifyActivityDateValidation]:
    '[Actual Start/Actual Complete] Date Time: skip validation; fill time on date set',
  // tslint:disable:max-line-length
  [DebugModeField.common]: `<ul><li>• Log needed required fields for Object saving</li><li>• Firebase token logs</li></ul>`,
  [DebugModeField.expandedGridItem]: 'Log Expanded Grid Item Data on each render',
  [DebugModeField.detailsOnClick]: `Alt (Option ⌥) + left mouse button click
    <ul><li>• Scheduler Row</li><li>• Activity Group (DDO Details -> Activities Tab)</li></ul>`,
  // tslint:disable:max-line-length
  // [DebugModeField.scheduler]: 'Log row data by row clicking (shift + left click)',
  [DebugModeField.datepicker]: 'Log datepicker dates on change',
  [DebugModeField.driverAssign]: 'Send multiple requests at once'
}

export const DebugMode = () => {
  const [state, setState] = React.useState<TDebugMode>(initialState)

  const toggle = (prop: string) => () => {
    const newState = { ...state, [prop]: !Boolean(state[prop]) }

    localStorage.setItem('debuggingMode', JSON.stringify(newState))
    setState(newState)
  }

  React.useEffect(() => {
    let storeValue = localStorage.getItem('debuggingMode')

    if (storeValue) {
      storeValue = JSON.parse(storeValue || ({} as any))
      const value = Object.keys(initialState).reduce(
        (acc, currKey) => {
          acc[currKey] = Boolean(storeValue[currKey])
          return acc
        },
        { ...initialState }
      )
      setState(value)
    }
  }, [])

  return (
    <>
      <Container>
        <div>
          <ul>
            {Object.keys(state).map(key => (
              <ListItem key={key} onClick={toggle(key)}>
                <Checkbox active={Boolean(oc(state)[key]())} />
                <span>{key}</span>
                {hints[key] && (
                  <span className={'mdi mdi-information'} data-for={'debugging-mode-hint'} data-tip={hints[key]} />
                )}
              </ListItem>
            ))}
          </ul>
          <TestSentry />
        </div>
        {state[DebugModeField.datepicker] && <TestDatepickerComponent />}
        {state[DebugModeField.diverActivity] && <TestDriverActivity />}
        <div />
      </Container>
      <ReactTooltip className={'react-tooltip'} id={'debugging-mode-hint'} html={true} />
    </>
  )
}

const Container = styled.div`
  display: flex;
`

const ListItem = styled.li`
  display: flex;
  align-items: center;
  padding: 5px 0;
  cursor: pointer;

  span {
    margin-left: 10px;
  }

  &:hover {
    color: #4555d0;
  }
`
