import * as React from 'react'
import { oc } from 'ts-optchain'
import styled from 'styled-components'
import { DriverReportDTO } from '../../../../api/origin/document-service'
import { DriverReportButton } from './DriverReportButton'
import { FieldContainer } from '../FieldContainer'
import { DateTimePicker } from '../DateTimePicker/views'
import { FieldWarning } from '../../FieldWarning'
import { useGridItemDriver } from '../../../../hooks/useGridItemDriver'
import { testDriverForReports } from '../../../../services/functions/test/testDriverForReports'

type Props = {
  reportType: DriverReportDTO.TypeEnum
  report: DriverReportDTO
  reportHistory: DriverReportDTO[]
  onGenerateReportClick: () => void
  disable?: boolean
}

export const DriverReport = (props: Props) => {
  const { disable, reportType, report, reportHistory, onGenerateReportClick } = props
  const { driver } = useGridItemDriver()
  const errorMessages = testDriverForReports(driver)
  const hasErrorMessages = Boolean(errorMessages.length)

  return (
    <FieldContainer
      title={reportType + ' Report'}
      errorMessageWidth={500}
      errorMessage={oc(report).status() === DriverReportDTO.StatusEnum.FAILED ? report.comment : undefined}
    >
      <Container>
        <DateTimePicker
          disabled={true}
          date={oc(report).date()}
          placeholder={'Report date'}
          hideTimeInput={true}
          onChange={undefined}
        />
        <ButtonContainer>
          <DriverReportButton
            disable={disable || hasErrorMessages}
            reportType={reportType}
            report={report}
            reportHistory={reportHistory}
            onGenerateReportClick={onGenerateReportClick}
          />
          {hasErrorMessages && (
            <FieldWarning
              title={'Required:'}
              messages={errorMessages}
              style={{
                position: 'absolute',
                left: 0,
                bottom: '100%',
                width: 'unset',
                height: 'unset',
                transform: 'translate(-70%, 30%)'
              }}
            />
          )}
        </ButtonContainer>
      </Container>
    </FieldContainer>
  )
}

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
`
const ButtonContainer = styled.div`
  position: relative;
`
