import { WebsocketEvent } from './websocket/interfaces'

export enum DebugModeField {
  common = 'Common',
  hideCommunicationHub = 'Hide Communication Hub',
  websoket = 'Websoket',
  expandedGridItem = 'Expanded Grid Item',
  scheduler = 'Scheduler',
  datepicker = 'Datepicker',
  driverAssign = 'Driver Assign',
  detailsOnClick = 'Details On Click',
  diverActivity = 'Driver Activity',
  simplifyActivityDateValidation = 'Simplify Activity Date Validation',
  testDriver = 'Test Driver'
}

export const getCookie = (name: string) => {
  const value = '; ' + document.cookie
  const parts = value.split('; ' + name + '=')
  if (parts.length === 2) {
    return parts
      .pop()
      .split(';')
      .shift()
  }
}

const getStoreDebuggingMode = (prop: DebugModeField): boolean => {
  const storeValue = localStorage.getItem('debuggingMode')
  const debuggingModeObj = storeValue ? JSON.parse(storeValue) : {}

  return debuggingModeObj[prop]
}

export const debuggingMode = {
  get common(): boolean {
    return getStoreDebuggingMode(DebugModeField.common)
  },
  get hideCommunicationHub(): boolean {
    return getStoreDebuggingMode(DebugModeField.hideCommunicationHub)
  },
  get datepicker(): boolean {
    return getStoreDebuggingMode(DebugModeField.datepicker)
  },
  get websoket(): boolean {
    return getStoreDebuggingMode(DebugModeField.websoket)
  },
  get expandedGridItem(): boolean {
    return getStoreDebuggingMode(DebugModeField.expandedGridItem)
  },
  get schedulerRow(): boolean {
    return getStoreDebuggingMode(DebugModeField.scheduler)
  },
  get driverAssign(): boolean {
    return getStoreDebuggingMode(DebugModeField.driverAssign)
  },
  get detailsOnClick(): boolean {
    return getStoreDebuggingMode(DebugModeField.detailsOnClick)
  },
  get diverActivity(): boolean {
    return getStoreDebuggingMode(DebugModeField.diverActivity)
  },
  get simplifyActivityDateValidation(): boolean {
    return getStoreDebuggingMode(DebugModeField.simplifyActivityDateValidation)
  },
  get testDriver(): boolean {
    return getStoreDebuggingMode(DebugModeField.testDriver)
  }
}

const color = {
  green: 'color: rgb(63, 182, 142);',
  yellow: 'color: #ffd600;',
  blue: 'color: rgb(58, 181, 194);',
  red: 'color: #ff5e5e;',
  grey: 'color: rgb(140, 162, 171);'
}

export const consoleLogStyles = {
  title: 'color: #4555d0; font-weight: 700',
  subtitle: 'color: rgb(140, 162, 171); font-weight: 700',
  details: 'color: rgb(140, 162, 171)',
  type: 'color: #bada55; font-weight: 700',
  colorText: color,
  colorBoldText: {
    green: color.green + ' font-weight: 700',
    yellow: color.yellow + ' font-weight: 700',
    blue: color.blue + ' font-weight: 700',
    red: color.red + ' font-weight: 700',
    grey: color.grey + ' font-weight: 700'
  },
  websocketAction: {
    [WebsocketEvent.CREATED]: color.green + ' font-weight: 700',
    [WebsocketEvent.UPDATED]: color.yellow + ' font-weight: 700',
    [WebsocketEvent.UPDATED_FORCE]: color.blue + ' font-weight: 700',
    [WebsocketEvent.DELETED]: color.red + ' font-weight: 700',
    DEFAULT: color.grey + ' font-weight: 700'
  }
}

export const isShowDetailsOnClick = (event: any): boolean => {
  return debuggingMode.detailsOnClick && event && event.altKey
}

export const showDetailsOnClick = (props: { title: string; data: any; blink?: { class: string; event: any } }) => {
  const { title, data, blink } = props
  // tslint:disable-next-line:no-console
  console.log(
    `%c[${DebugModeField.detailsOnClick}] %c${title}`,
    consoleLogStyles.title,
    consoleLogStyles.colorBoldText.yellow
  )
  // tslint:disable-next-line:no-console
  console.log(data)

  if (blink) {
    const element = blink.event.target.closest(`.${blink.class}`)
    if (element) {
      element.style.transition = '0.1s'
      element.style.opacity = '0.5'

      setTimeout(() => {
        if (element) {
          element.style.transition = ''
          element.style.opacity = ''
        }
      }, 300)
    }
  }

  // if (window.getSelection) {
  //   window.getSelection().removeAllRanges()
  // }
}
